import { useState, createContext } from "react";
import { getAttributes } from "../../httpapi";

export const AttributeContext = createContext(null);

const getAttributeForKey = (attributes, key, category, id) =>
  attributes[key]?.[category]?.find(a => a.id === id);

const getAttributeLabelForKey = (attributes, key, category, id) =>
  attributes[key]?.[category]?.find(a => a.id === id)?.label || "";

export function AttributeProvider({ children }) {
  const [attributes, setAttributes] = useState({});

  const fetchAttributes = async ({ categories, regionCode, locale }) => {
    const result = { ...attributes };
    const cacheKey = regionCode || "default";
    if (!result[cacheKey]) {
      result[cacheKey] = {};
    }

    const missingCategories = [];
    categories.forEach(c => {
      if (!result[cacheKey][c]) {
        missingCategories.push(c);
        return;
      }
    });

    if (missingCategories.length) {
      const attributes = await getAttributes({
        categories: missingCategories,
        locale,
        regionCode,
      });
      if (attributes === null) {
        return;
      }
      Object.keys(attributes).forEach(c => {
        result[cacheKey][c] = attributes[c];
      });
      setAttributes({
        ...result,
      });
    }

    return result;
  };

  /**
   * getAttributeLabel will return the localized label
   * for a given attribute id and category. The search
   * function will look through each cache key for the
   * requested attribute id. Optionally a user can
   * pass in a key value, which will search only that
   * cache key for the label
   */
  const getAttributeLabel = ({ key, category, id }) => {
    if (!key) {
      return Object.keys(attributes).reduce((acc, k) => {
        if (acc !== "") {
          return acc;
        }
        return getAttributeLabelForKey(attributes, k, category, id);
      }, "");
    }
    return getAttributeLabelForKey(attributes, key, category, id);
  };

  const getAttribute = ({ category, id }) => {
    return Object.keys(attributes).reduce((acc, k) => {
      if (acc) {
        return acc;
      }
      return getAttributeForKey(attributes, k, category, id);
    }, null);
  };

  return (
    <AttributeContext.Provider
      value={{
        attributes,
        fetchAttributes,
        getAttributeLabel,
        getAttribute,
      }}
    >
      {children}
    </AttributeContext.Provider>
  );
}

export default AttributeProvider;
