import styled from "styled-components";
import { rawTimeZones } from "@vvo/tzdb";

import {
  swapCopyVariables,
  getDisplayPhoneNumber,
} from "@sussex/react-kit/utils";

import useCopy from "./useCopy";

const getTimezoneAbbreviation = tz => {
  const value = rawTimeZones.find(timeZone => {
    return timeZone.name === tz || timeZone.group.includes(tz);
  });

  return value ? value.abbreviation : "";
};

const formatDate = (timestamp, tz) => {
  const date = new Date(timestamp * 1000).toLocaleString(navigator.language, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: tz,
  });
  const timezone = getTimezoneAbbreviation(tz);
  return `${date} ${timezone}`;
};

const Link = styled.a`
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  word-break: break-all;
`;

const PhoneLink = styled(Link)`
  white-space: nowrap;
`;

const useAppointmentDetails = match => {
  const [
    appointmentDetailsWherePhone,
    appointmentDetailsWhereOnline,
    appointmentDetailsHowPhone,
    appointmentDetailsHowSessions,
    appointmentDetailsHowOnline,
    appointmentDetailsHowOther,
  ] = useCopy([
    "client.clientMessage.appointmentDetails.where.phone",
    "client.clientMessage.appointmentDetails.where.online",
    "client.clientMessage.appointmentDetails.how.phone",
    "client.clientMessage.appointmentDetails.how.sessions",
    "client.clientMessage.appointmentDetails.how.online",
    "client.clientMessage.appointmentDetails.how.other",
  ]);

  const therapist = match.therapist;

  const getWhere = () => {
    switch (match.appointmentMethod) {
      case "phone":
        return appointmentDetailsWherePhone;
      case "sessions":
        return appointmentDetailsWhereOnline;
      case "other":
        return match.otherVideoDetail ? appointmentDetailsWhereOnline : "";
      default:
        return "";
    }
  };

  const getWhen = () => {
    return formatDate(match.proposedDatetime, match.profileTimezone);
  };

  const getHow = () => {
    switch (match.appointmentMethod) {
      case "phone":
        return swapCopyVariables(appointmentDetailsHowPhone, {
          THERAPIST_NAME: therapist?.fullName,
          CLIENT_NUMBER: (
            <PhoneLink href={`tel:${match.phoneNumber}`}>
              {getDisplayPhoneNumber({
                number: match.phoneNumber,
                countryCode: match.location.country,
              })}
            </PhoneLink>
          ),
        });
      case "sessions":
        return swapCopyVariables(appointmentDetailsHowSessions, {
          SESSIONS_URL: (
            <Link href={match.sessionsUrl}>{match.sessionsUrl}</Link>
          ),
        });
      case "other":
        if (match.otherVideoDetail) {
          return swapCopyVariables(appointmentDetailsHowOnline, {
            VIDEO_DETAIL: match.otherVideoDetail,
          });
        } else {
          return (
            match.appointmentDetail &&
            swapCopyVariables(appointmentDetailsHowOther, {
              APPOINTMENT_DETAIL: match.appointmentDetail,
            })
          );
        }
      default:
        return null;
    }
  };

  return {
    where: getWhere(),
    when: getWhen(),
    how: getHow(),
  };
};

export default useAppointmentDetails;
