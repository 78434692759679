import styled from "styled-components";
import { H2 } from "@sussex/react-kit/elements";
import illustration from "../../../assets/therapist-illustration.png";

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    gap: 16px;
  }
`;

const Illustration = styled.img`
  pointer-events: none;
  width: 45px;
`;

const Title = styled(H2)`
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  line-height: 1.25;
`;

const HeaderSmall = ({ title }) => {
  return (
    <Wrapper>
      <Illustration src={illustration} />
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default HeaderSmall;
