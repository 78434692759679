import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Anchor, ErrorBox, InfoBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../hooks/useCopy";
import { tokenGetMatch, tokenDeclineMatch, userTypes } from "../httpapi";
import PanelHeader from "../components/PanelHeader";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  margin-bottom: 30px;
  text-align: center;
`;

const Message = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 30px;
  text-align: center;
  padding: 0 40px;
`;

const Inner = styled.div`
  max-width: 525px;
  margin: 10px auto 0;
  padding: 16px;
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
`;

const Button = styled.button`
  appearance: none;
  background: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.primaryBlue : theme.colors.white};
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.white : theme.colors.default};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;

  &:hover {
    background: ${({ theme, isBlue }) =>
      isBlue ? theme.colors.vibrantBlue : theme.colors.light};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme, isBlue }) =>
        isBlue ? theme.colors.transparentGreyText : theme.colors.gray};
      cursor: default;
    `};
`;

const Body = styled.p`
  margin: 20px 12px;
  display: block;
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.4;
`;

const Link = styled(Anchor)`
  font-family: ${({ theme }) => theme.fonts.primary};
  text-decoration: underline;
`;

const memberUrl = process.env.REACT_APP_MEMBER_URL;
const availabilityUrl = `${memberUrl}/us/profile/availability`;
const messageCenterUrl = `${memberUrl}/us/clients/message-center`;

export default function TherapistDeclineAppointmentView() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [match, setMatch] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [processing, setProcessing] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  const [
    headerText,
    titleText,
    messageText,
    noBtnText,
    yesBtnText,
    feedbackHeaderText,
    feedbackMessageText,
    feedbackLinktext,
    feedbackUpdateAvailability,
    errorTitle,
    genericErrorText,
  ] = useCopy([
    "client.therapistAppointmentDecline.header",
    "client.therapistAppointmentDecline.title",
    "client.therapistAppointmentDecline.text",
    "client.therapistAppointmentDecline.noButtonText",
    "client.therapistAppointmentDecline.yesButtonText",
    "client.therapistAppointmentDecline.feedbackHeader",
    "client.therapistAppointmentDecline.feedbackText",
    "client.therapistAppointmentDecline.feedbackLink",
    "client.therapistAppointmentDecline.feedbackUpdateAvailability",
    "client.confirmLanding.errorTitle",
    "client.confirmLanding.genericError",
  ]);

  useEffect(() => {
    if (!token) {
      setError("generic");
      return;
    }

    const load = async () => {
      const res = await tokenGetMatch({ token, userType: userTypes.THERAPIST });
      if (res.success) {
        setMatch(res);
      } else {
        setError("generic");
      }
      setLoading(false);
    };

    load();
  }, [token]);

  const goToMatchInMessageCenter = () => {
    window.open(`${messageCenterUrl}/${match.id}`);
  };

  const declineMatch = async () => {
    setProcessing(true);

    const res = await tokenDeclineMatch({ token });
    if (res.success) {
      setShowFeedback(true);
    } else {
      setError("generic");
    }

    setProcessing(false);
  };

  if (loading) {
    return (
      <PageWrapper>
        <PanelWrapper>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <PanelWrapper>
          <Panel>
            <PanelHeader title={errorTitle} />
            <Inner>
              <ErrorBox>{genericErrorText}</ErrorBox>
            </Inner>
          </Panel>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  if (showFeedback) {
    return (
      <PageWrapper>
        <PanelWrapper>
          <Panel>
            <PanelHeader title={feedbackHeaderText} />
            <Inner>
              <InfoBox>{feedbackMessageText}</InfoBox>
              <Body>
                {swapCopyVariables(feedbackUpdateAvailability, {
                  LINK: <Link href={availabilityUrl}>{feedbackLinktext}</Link>,
                })}
              </Body>
            </Inner>
          </Panel>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <Panel>
          <PanelHeader title={headerText} />
          <Inner>
            <Subtitle>{titleText}</Subtitle>
            <Message>{messageText}</Message>
            <Button onClick={declineMatch} disabled={processing} isBlue>
              {yesBtnText}
            </Button>
            <Button onClick={goToMatchInMessageCenter} disabled={processing}>
              {noBtnText}
            </Button>
          </Inner>
        </Panel>
      </PanelWrapper>
    </PageWrapper>
  );
}
