import { useContext, useState } from "react";
import styled, { css, withTheme } from "styled-components";
import { Loading } from "@sussex/react-kit/assets";
import { H2, Button } from "@sussex/react-kit/elements";
import ErrorIcon from "../../assets/Error";
import useCopy from "../../hooks/useCopy";
import { ErrorContext } from "../../providers/ErrorProvider";

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  flex: 1;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  inset: 0;
  z-index: 1;
  border-radius: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  background: white;

  ${({ $border }) =>
    $border &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.border};
      box-shadow: 0px 4px 15px rgba(31, 41, 55, 0.15);
      border-radius: 16px;
    `};
`;

const ErrorHeaderWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-right: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
`;

const ErrorHeader = styled(H2)``;

const ErrorBody = styled.div`
  width: 100%;
`;

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 16px 0;
`;

const ErrorMessage = ({ border, theme }) => {
  const { error } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const {
    colors: { default: defaultColor, border: borderColor },
  } = theme;
  const [defaultTitleText, defaultButtonText, defaultCancelButtonText] =
    useCopy([
      "client.error.title",
      "client.error.retry",
      "client.error.cancel",
    ]);

  if (!error) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);
    await error.onClick();
    setLoading(false);
  };

  return (
    <Overlay>
      <Wrapper $border={border}>
        <ErrorHeaderWrapper>
          <ErrorIcon variant="error" />
          <ErrorHeader>{error.title || defaultTitleText}</ErrorHeader>
        </ErrorHeaderWrapper>
        <ErrorBody>
          <ErrorText>{error.message}</ErrorText>
          <ButtonWrapper>
            {error.onCancel && (
              <Button variant="white" full onClick={error.onCancel}>
                {error.cancelButtonText || defaultCancelButtonText}
              </Button>
            )}
            <Button
              variant="white"
              full
              onClick={handleClick}
              disabled={loading}
            >
              {loading ? (
                <Loading
                  size={12}
                  outerColor={borderColor}
                  innerColor={defaultColor}
                />
              ) : (
                error.buttonText || defaultButtonText
              )}
            </Button>
          </ButtonWrapper>
        </ErrorBody>
      </Wrapper>
    </Overlay>
  );
};

export default withTheme(ErrorMessage);
