import styled from "styled-components";
import { TextArea } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";

const TextBox = styled(TextArea)`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const CharCounter = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, valid }) =>
    valid ? theme.colors.green : theme.colors.red};
  line-height: 1;
  padding: 10px 0;
  text-align: right;
`;

const LimitedTextBox = ({
  onChange,
  maxLength,
  counterText,
  message,
  rows = 8,
}) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <>
      <TextBox onChange={handleChange} rows={rows} value={message} />
      <CharCounter valid={message.length <= maxLength}>
        {swapCopyVariables(counterText, {
          COUNT: message.length,
          LIMIT: maxLength,
        })}
      </CharCounter>
    </>
  );
};

export default LimitedTextBox;
