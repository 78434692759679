import { useState, useContext } from "react";
import styled from "styled-components";
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Passcode } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { UserContext } from "../../providers/UserProvider";
import { loginSuccess, loginFailed } from "../../metrics";

const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const OTP = ({ email, cognitoUser }) => {
  const [
    codeResentText,
    enterPasscodeTitle,
    enterPasscodeBody,
    resendText,
    resendLinkText,
    errorInvalidCodeText,
    tooManyAttemptsText,
    tooManyAttemptsLinkText,
    expiredText,
    expiredLinkText,
    genericError,
  ] = useCopy([
    "client.confirmAccount.passcodeResent",
    "client.confirmAccount.enterPasscodeTitle",
    "client.confirmAccount.enterPasscodeBody",
    "client.confirmAccount.resend",
    "client.confirmAccount.resendLink",
    "client.confirmAccount.errorInvalidCode",
    "client.confirmAccount.tooManyAttempts",
    "client.confirmAccount.tooManyAttemptsLink",
    "client.confirmAccount.expired",
    "client.confirmAccount.expiredLink",
    "client.error.title",
  ]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeResent, setCodeResent] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { signInUser } = useContext(UserContext);

  const reAuthenticate = () =>
    new Promise(resolve => {
      setDisabled(false);
      setLoading(true);
      setCodeResent(false);
      setError(null);
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
      });
      cognitoUser.initiateAuth(authenticationDetails, {
        onFailure: err => {
          setLoading(false);
          setError(genericError);
          console.error(err.message || JSON.stringify(err));
        },
        customChallenge: () => {
          setCodeResent(
            swapCopyVariables(codeResentText, {
              EMAIL: email,
            }),
          );
          setLoading(false);
          return resolve();
        },
      });
    });

  const submit = code =>
    new Promise(resolve => {
      setLoading(true);
      setCodeResent(false);
      setError(null);
      cognitoUser.sendCustomChallengeAnswer(code, {
        onSuccess: async result => {
          cognitoUser.getUserAttributes((err, attrResult) => {
            if (err) {
              console.error("Error getting user attributes", err);
              setError(genericError);
              return;
            }
            let uuid = "",
              name = "",
              phoneNumber = "",
              locale = navigator.language;

            attrResult.forEach(a => {
              if (a.Name === "sub") {
                uuid = a.Value;
              }
              if (a.Name === "name") {
                name = a.Value;
              }
              if (a.Name === "phone_number") {
                phoneNumber = a.Value;
              }
              if (a.Name === "locale") {
                locale = a.Value;
              }
            });
            const idToken = result.idToken.jwtToken;

            loginSuccess({ clientUuid: uuid });
            signInUser({
              uuid,
              signedIn: true,
              verified: true,
              username: email,
              name,
              email,
              phoneNumber,
              locale,
              idToken,
            });
          });
          resolve();
          return;
        },
        onFailure: async err => {
          loginFailed({ reason: err.message });
          console.error(err.message || JSON.stringify(err));
          // Expired and too many attempt failures throw the same
          // NotAuthorizedException error, so it's necessary to check the message.
          setLoading(false);
          switch (err.message) {
            case "Invalid session for the user.": //expired
              setDisabled(true);
              setError(
                swapCopyVariables(expiredText, {
                  LINK: <Link onClick={reAuthenticate}>{expiredLinkText}</Link>,
                }),
              );
              resolve();
              return;
            case "Incorrect username or password.": // too many attempts
              setDisabled(true);
              setError(
                swapCopyVariables(tooManyAttemptsText, {
                  LINK: (
                    <Link onClick={reAuthenticate}>
                      {tooManyAttemptsLinkText}
                    </Link>
                  ),
                }),
              );
              resolve();
              return;
            default:
              setError(genericError);
              resolve();
              return;
          }
        },
        customChallenge: () => {
          setLoading(false);
          setError(errorInvalidCodeText);
          resolve();
        },
      });
    });

  return (
    <Passcode
      onSubmit={submit}
      resend={reAuthenticate}
      loading={loading}
      enterPasscodeTitle={enterPasscodeTitle}
      enterPasscodeBody={enterPasscodeBody}
      email={email}
      resendText={resendText}
      resendLinkText={resendLinkText}
      disabled={disabled}
      error={error}
      success={codeResent}
    />
  );
};

export default OTP;
