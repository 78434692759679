import { sendMetric } from "./httpapi";

const sendFeedback = async ({ matchId, feedbackType, message }) => {
  return await sendMetric({
    name: "UserFeedback",
    value: message,
    metadata: {
      matchId,
      feedbackType,
    },
  });
};

const loginSuccess = async ({ clientUuid }) => {
  return await sendMetric({
    name: "SystemAction",
    value: "LoginSuccess",
    metadata: {
      clientUuid,
    },
  });
};

const loginFailed = async ({ reason }) => {
  return await sendMetric({
    name: "SystemAction",
    value: "LoginFailed",
    metadata: {
      reason,
    },
  });
};

const viewProfileClicked = async ({ itemId, profileId }) => {
  return await sendMetric({
    name: "UserAction",
    value: "ViewProfileClicked",
    metadata: {
      itemId,
      profileId,
    },
  });
};

const chatMessageSent = async ({ itemId }) => {
  return await sendMetric({
    name: "UserAction",
    value: "ChatMessageSent",
    metadata: {
      itemId,
    },
  });
};

const initialPageLoad = async payload => {
  return await sendMetric({
    name: "UserAction",
    value: "InitialPageLoad",
    metadata: payload,
  });
};

export {
  sendFeedback,
  loginSuccess,
  loginFailed,
  viewProfileClicked,
  chatMessageSent,
  initialPageLoad,
};
