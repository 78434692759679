import styled from "styled-components";
import { Anchor } from "@sussex/react-kit/elements";
import useCopy from "../../../hooks/useCopy";

const Link = styled(Anchor)`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
  margin-top: 24px;
`;

const HomeLink = () => {
  const [homeLink] = useCopy(["client.feedback.backToHome"]);

  return <Link href="/">{homeLink}</Link>;
};

export default HomeLink;
