import { Statuses } from "@sussexdir/match-utils";

const isIframe = window.self !== window.top;
const isPopup = !!window.opener;

const postMessageToParent = msg => {
  let opener = null;
  if (isIframe) {
    opener = window.parent;
  }
  if (isPopup) {
    opener = window.opener;
  }
  if (!opener) {
    return;
  }
  opener.postMessage(msg, "*");
};

export default postMessageToParent;

export const obfuscateEmail = email => {
  if (!email) {
    return;
  }
  const parts = email.split("@");
  const name = parts[0];
  return (
    name[0] +
    "*".repeat(name.length - 2) +
    name[name.length - 1] +
    "@" +
    parts[1]
  );
};

export const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

export const isChatEnabled = match => {
  return (
    match &&
    ([Statuses.confirmed, Statuses.scheduled, Statuses.accepted].includes(
      match.status,
    ) ||
      (match.status === Statuses.resolved &&
        [Statuses.confirmed, Statuses.scheduled].includes(
          match.previousStatus,
        )))
  );
};

export const getLocale = () => {
  const locales = {
    "en-US": "en-US",
    en: "en-US",
  };

  const lang = navigator.language;

  // try exact match
  if (typeof locales[lang] !== "undefined") {
    return locales[lang];
  }

  // try country code
  const country = lang.split("-")[0];

  if (typeof locales[country] !== "undefined") {
    return locales[country];
  }

  // default language
  return "en-US";
};

export const getPhotoUrl = id => {
  return `${process.env.REACT_APP_BASE_DIRECTORY_API_URL}/profile-photo/${id}`;
};

export const truncateString = (str, maxLength = 25) => {
  if (str.length > maxLength) {
    return str.replace(/\.\.\.$/, "").substring(0, maxLength) + "...";
  }
  return str;
};
