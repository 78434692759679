import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { isMobile } from "@sussex/react-kit/utils";

const ddLogsEnabled = process.env.REACT_APP_DATADOG_LOG_TOKEN !== "";

const ddRumEnabled =
  process.env.REACT_APP_DATADOG_RUM_APP_ID !== "" &&
  process.env.REACT_APP_DATADOG_RUM_TOKEN !== "";

const ddReplaySampleRate =
  parseInt(process.env.REACT_APP_DATADOG_REPLAY_SAMPLE_RATE) || 0;

export const datadogInit = () => {
  if (ddLogsEnabled) {
    // Initialize Datadog Logs
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
      site: "datadoghq.com",
      service: "client.psychologytoday.com",
      env: process.env.REACT_APP_ENVIRONMENT,
      silentMultipleInit: true,
    });
  }

  // Initialize Datadog RUM
  if (ddRumEnabled) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_TOKEN,
      site: "datadoghq.com",
      service: "client.psychologytoday.com",
      env: process.env.REACT_APP_ENVIRONMENT,
      silentMultipleInit: true,
      sampleRate: 100,
      sessionReplaySampleRate: ddReplaySampleRate,
      resourceSampleRate: 100,
      beforeSend: event => {
        // Log 5xx errors as RUM errors
        if (event.type === "resource" && event.resource.status_code >= 500) {
          datadogRum.addError(
            `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
          );
        }
      },
    });

    datadogRum.addRumGlobalContext(
      "device_type",
      isMobile ? "mobile" : "desktop",
    );

    if (ddReplaySampleRate > 0) {
      datadogRum.startSessionReplayRecording();
    }
  }
};

export const datadogSetPage = page => {
  datadogRum.addRumGlobalContext("page", page);

  datadogEvent({
    category: "page",
    event: "viewed",
  });
};

export const datadogEvent = ({
  category,
  event,
  feature = null,
  component = null,
  metadata = null,
}) => {
  // Event name format: evt.<category>[.<feature>].<event>
  // component is stored together with metadata in the context of the event
  let name = `evt.${category}`;
  if (feature) {
    name += `.${feature}`;
  }
  name += `.${event}`;

  const ctx = metadata ? { ...metadata } : {};
  if (component) {
    ctx.component = component;
  }

  datadogRum.addAction(name, ctx);
};
