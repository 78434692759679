import { theme } from "@sussex/react-kit";

theme.breakpointsInt = {
  ">mobile": 768,
  ">tablet": 900,
};

theme.headerHeight = 69;

theme.mediaQueries = {
  mobile: "(max-width: 767px)",
  ">mobile": "(min-width: 768px)",
  ">tablet": "(min-width: 900px)",
};

// Add `@media` prefix to mediaQueries to be used as CSS media query rule
theme.breakpoints = Object.keys(theme.mediaQueries).reduce((accum, curr) => {
  accum[curr] = `@media${theme.mediaQueries[curr]}`;
  return accum;
}, {});

export default theme;
