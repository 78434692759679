let dataMemory = {};

export default class MemoryStorage {
  static setItem(key, value) {
    dataMemory[key] = value;
    return dataMemory[key];
  }
  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
  }
  static removeItem(key) {
    return delete dataMemory[key];
  }
  static clear() {
    dataMemory = {};
    return dataMemory;
  }
}
