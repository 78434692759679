import styled from "styled-components";
import { H3, Button, Link } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { useContext } from "react";
import { InboxContext } from "../../providers/InboxProvider";
import { RequestStatuses } from "@sussexdir/match-utils";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Title = styled(H3)`
  text-align: center;
`;

const Continue = styled(Button)`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  padding: 12px 16px;
`;

const Cancel = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

let requestActionsEnabled = false;
try {
  requestActionsEnabled =
    document.cookie.indexOf("match-continue-searching-enabled=1") >= 0;
} catch (e) {
  console.log(e);
}

const RequestActions = ({ activeRequest }) => {
  const { setShowCancelConfirm, setShowContinueSearchingConfirm } =
    useContext(InboxContext);
  const [titleText, continueText, cancelText] = useCopy([
    "client.requestActions.title",
    "client.requestActions.continue",
    "client.requestActions.cancel",
  ]);

  if (!requestActionsEnabled) {
    return null;
  }

  if (
    activeRequest.status === RequestStatuses.open &&
    activeRequest.matchesCreationMode === "default" &&
    activeRequest.matches.length > 0
  ) {
    return (
      <Wrapper>
        <Title>{titleText}</Title>
        <Continue onClick={() => setShowContinueSearchingConfirm(true)} full>
          {continueText}
        </Continue>
        <Cancel onClick={() => setShowCancelConfirm(true)}>{cancelText}</Cancel>
      </Wrapper>
    );
  }

  if (activeRequest.active) {
    return (
      <Cancel onClick={() => setShowCancelConfirm(true)}>{cancelText}</Cancel>
    );
  }

  return null;
};

export default RequestActions;
