import styled from "styled-components";
import { InfoBox } from "@sussex/react-kit/elements";
import {
  swapCopyVariables,
  getDisplayPhoneNumber,
} from "@sussex/react-kit/utils";
import { Statuses } from "@sussexdir/match-utils";
import useCopy from "../../hooks/useCopy";
import PanelHeader from "../../components/PanelHeader";
import PageWrapper from "./PageWrapper";
import PanelWrapper from "./PanelWrapper";
import LoginLink from "./LoginLink";

const Inner = styled.div`
  max-width: 500px;
  margin: 30px auto 0;
`;

const LoginWrapper = styled.div`
  margin: 30px 0;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
  width: 100%;
  margin: 0 auto;
`;

export default function AlreadyClickedView({ match }) {
  const [
    scheduledBody,
    scheduledBodyNoPhone,
    scheduledMessage,
    confirmedBody,
    confirmedBodyNoPhone,
    confirmedMessage,
    declinedBody,
    declinedMessage,
    errorTitle,
  ] = useCopy([
    "client.feedback.scheduled.body",
    "client.feedback.scheduled.body.noPhone",
    "client.feedback.scheduled.message",
    "client.feedback.confirmed.body",
    "client.feedback.confirmed.body.noPhone",
    "client.feedback.confirmed.message",
    "client.feedback.declined.body",
    "client.feedback.declined.message",
    "client.confirmLanding.errorTitle",
  ]);

  const getPhone = () =>
    getDisplayPhoneNumber({
      number: match.therapist.phoneNumber.number,
      extension: match.therapist.phoneNumber.extension,
      countryCode: match?.location?.country || "US",
    });
  const name = match.therapist.fullName;

  let message = "",
    body = "";

  switch (match.status) {
    case Statuses.confirmed:
      body = match.therapist.phoneNumber?.number
        ? swapCopyVariables(confirmedBody, {
            LOGIN: <LoginLink />,
            THERAPIST_NAME: name,
            PHONE_NUMBER: getPhone(),
          })
        : swapCopyVariables(confirmedBodyNoPhone, {
            LOGIN: <LoginLink />,
            THERAPIST_NAME: name,
          });
      message = confirmedMessage;
      break;
    case Statuses.scheduled:
      const date = new Date(match.proposedDatetime * 1000);
      const options = {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      };

      const dateString = date.toLocaleDateString(navigator.language, options);

      body = match.therapist.phoneNumber?.number
        ? swapCopyVariables(scheduledBody, {
            LOGIN: <LoginLink />,
            THERAPIST_NAME: name,
            PHONE_NUMBER: getPhone(),
          })
        : swapCopyVariables(scheduledBodyNoPhone, {
            LOGIN: <LoginLink />,
            THERAPIST_NAME: name,
          });
      message = swapCopyVariables(scheduledMessage, { DATE: dateString });
      break;
    case Statuses.resolved:
    case Statuses.declined:
    case Statuses.closed:
      body = swapCopyVariables(declinedBody, {
        LOGIN: <LoginLink />,
      });
      message = declinedMessage;
      break;
    default:
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <Panel>
          <PanelHeader title={errorTitle} back={null} close={false} />
          <Inner>
            <InfoBox>{message}</InfoBox>
            <LoginWrapper>{body}</LoginWrapper>
          </Inner>
        </Panel>
      </PanelWrapper>
    </PageWrapper>
  );
}
