import { useState, useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../hooks/useCopy";
import {
  userTypes,
  tokenRefuseMatch,
  tokenGetMatch,
  getTherapist,
} from "../httpapi";
import PanelHeader from "../components/PanelHeader";
import { useNavigate } from "react-router-dom";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";
import AlreadyClicked from "./shared/AlreadyClicked";
import LoginLink from "./shared/LoginLink";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const Inner = styled.div`
  max-width: 525px;
  margin: 10px auto 0;
  padding: 16px;
`;

const LoginWrapper = styled.div`
  margin: 30px 0;
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
`;

export default function DeclineAppointmentView() {
  const [error, setError] = useState(null);
  const [match, setMatch] = useState(null);
  const navigate = useNavigate();

  const [loginErrorText, errorTitle, genericErrorText] = useCopy([
    "client.confirmLanding.loginText.error",
    "client.confirmLanding.errorTitle",
    "client.confirmLanding.genericError",
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!token) {
      setError("generic");
      return;
    }

    const load = async () => {
      const matchRes = await tokenGetMatch({
        token,
        userType: userTypes.CLIENT,
      });
      if (!matchRes.success) {
        setError("generic");
        return;
      }

      const therapistRes = await getTherapist(matchRes.profileId);
      if (!therapistRes.success) {
        setError("generic");
        return;
      }

      matchRes.therapist = therapistRes;
      setMatch(matchRes);

      const res = await tokenRefuseMatch({ token });
      if (res.success) {
        navigate("/feedback/decline-match", {
          state: {
            token,
            matchId: matchRes.id,
          },
        });
        return;
      }
      setError(res.conflict ? "conflict" : "generic");
    };

    load();
  }, [navigate]);

  if (error) {
    if (error === "conflict") {
      return <AlreadyClicked match={match} />;
    }
    return (
      <PageWrapper>
        <PanelWrapper>
          <Panel>
            <PanelHeader title={errorTitle} />
            <Inner>
              <ErrorBox>{genericErrorText}</ErrorBox>
              <LoginWrapper>
                {swapCopyVariables(loginErrorText, { LINK: <LoginLink /> })}
              </LoginWrapper>
            </Inner>
          </Panel>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </PanelWrapper>
    </PageWrapper>
  );
}
