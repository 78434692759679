import { useContext, useEffect } from "react";
import { useTheme } from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@sussex/react-kit/hooks";

import { RequestContext } from "../providers/RequestProvider";

const useRewriteUrl = () => {
  const { requestId, matchId } = useParams();
  const { breakpointsInt } = useTheme();
  const { requests, loading } = useContext(RequestContext);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    if (loading) {
      return;
    }

    // No request and no match...go to the first request
    if (!requestId && !matchId) {
      navigate(`/requests/${requests[0].id}`);
      return;
    }

    if (requestId.startsWith("match")) {
      const matches = requests.flatMap(r => r.matches)
      const match = matches.find(i => i.id === requestId);
      navigate(`/requests/${match.requestId}/${match.id}`);
      return;
    }

    if (!matchId) {
      const isDesktop = width >= breakpointsInt[">mobile"];
      if (!isDesktop) {
        return;
      }

      const activeRequest =
        requests.find(i => i.id === requestId) || requests[0];

      const activeMatches = activeRequest.matches?.sort(
        (a, b) => b.lastActivityTimestamp - a.lastActivityTimestamp,
      );

      if (activeMatches.length > 0) {
        navigate(`/requests/${activeRequest.id}/${activeMatches[0].id}`);
      }
    }
  }, [loading, requests, requestId, matchId, width, breakpointsInt, navigate]);
};

export default useRewriteUrl;
