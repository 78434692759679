const FemaleFace = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_905_27346)">
        <path
          d="M1.99977 15.749C1.59915 15.0514 1.31953 14.291 1.17277 13.5C0.867772 11.542 1.99977 10.4 1.99977 7C1.99977 5.4087 2.63191 3.88258 3.75713 2.75736C4.88235 1.63214 6.40847 1 7.99977 1C9.59107 1 11.1172 1.63214 12.2424 2.75736C13.3676 3.88258 13.9998 5.4087 13.9998 7C13.9998 10.4 15.1318 11.543 14.8268 13.5C14.68 14.291 14.4004 15.0514 13.9998 15.749"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9999 15.3696L11.2459 15.2286C10.9022 15.164 10.5919 14.9813 10.3686 14.7122C10.1452 14.443 10.023 14.1043 10.0229 13.7546V12.6846"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.015 12.6846V13.7546C6.01491 14.1044 5.89252 14.4433 5.669 14.7125C5.44548 14.9816 5.1349 15.1642 4.791 15.2286L4 15.3776"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 11.0001C11.1123 11.5827 10.5941 12.0668 9.98647 12.414C9.3789 12.7612 8.69873 12.9619 8 13.0001C7.30075 12.9612 6.62023 12.7596 6.01262 12.4113C5.405 12.0631 4.88706 11.5778 4.5 10.9941"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 11.9998L11.159 10.6598C10.832 10.3329 10.6283 9.90273 10.5827 9.44254C10.5371 8.98235 10.6524 8.52059 10.909 8.13584L11.16 7.76084C11.4018 7.398 11.5184 6.96606 11.4921 6.53081C11.4657 6.09557 11.2978 5.68086 11.014 5.34984L9.077 3.08984C8.78098 4.21055 8.12245 5.20182 7.20409 5.90908C6.28572 6.61635 5.15914 6.99987 4 6.99984L4.849 8.41484C5.04401 8.73932 5.14293 9.11247 5.13426 9.49094C5.12559 9.86941 5.00967 10.2376 4.8 10.5528L3.5 12.4998"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_27346">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FemaleFace;
