import styled from "styled-components";
import { Link, SubmitButton } from "@sussex/react-kit/elements";

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
`;

const SkipWrapper = styled.div`
  margin-top: 8px;
`;
const Buttons = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 400px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Footer = ({ onNext, onSkip, nextText, skipText, disabled }) => {
  return (
    <Wrapper>
      <Buttons>
        {onNext && (
          <ButtonWrapper>
            <SubmitButton onClick={onNext} disabled={disabled} full>
              {nextText}
            </SubmitButton>
          </ButtonWrapper>
        )}
      </Buttons>
      {skipText && (
        <SkipWrapper>
          <Link onClick={onSkip}>{skipText}</Link>
        </SkipWrapper>
      )}
    </Wrapper>
  );
};

export default Footer;
