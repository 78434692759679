import { useContext, useEffect, useRef, forwardRef } from "react";
import styled from "styled-components";
import MatchHeader from "./MatchHeader";
import ChatInput from "../Chat/ChatInput";
import { RequestContext } from "../../providers/RequestProvider";
import { useIsActive } from "@sussex/react-kit/hooks";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
`;

const InputWrapper = styled.div`
  justify-self: flex-end;
  margin: 0 16px 16px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DetailWrapper = forwardRef(({ match, children }, ref) => {
  const { readMatch } = useContext(RequestContext);
  const { lastActivityDate } = useIsActive();
  const working = useRef(false);

  useEffect(() => {
    // this effect is executed on every second when there's
    // user activity on the match, so be careful if you are
    // updating this logic
    if (
      !match ||
      !match.newActivityForClient ||
      !lastActivityDate ||
      working.current
    ) {
      return;
    }

    working.current = true;

    const markMatchAsViewed = async () => {
      await readMatch(match);
      working.current = false;
    };

    markMatchAsViewed();
  }, [lastActivityDate, match, readMatch]);

  return (
    <Wrapper>
      <MatchHeader match={match} />
      <Content ref={ref}>{children}</Content>
      <InputWrapper>
        <ChatInput match={match} />
      </InputWrapper>
    </Wrapper>
  );
});

export default DetailWrapper;
