import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Statuses } from "@sussexdir/match-utils";
import { swapCopyVariables } from "@sussex/react-kit/utils";

import useCopy from "../../hooks/useCopy";
import BaristaGirl from "../../assets/therapist-illustration.png";
import backArrow from "../../assets/back-arrow-thin.svg";
const questionnaireLink = `${process.env.REACT_APP_DIRECTORY_URL}/match`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  max-width: 270px;
  margin: 0 auto;
`;

const Illustration = styled.img`
  pointer-events: none;
  width: 100px;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  text-align: center;
  line-height: 150%;
`;

const Message = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  text-align: center;
  line-height: 150%;
`;

const BackLink = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-align: center;
  line-height: 150%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: none;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const RequestPlaceholder = ({ request }) => {
  const navigate = useNavigate();
  const requestId = request.id;
  const [
    pendingTitle,
    pendingMessage,
    canceledTitle,
    canceledMessage,
    canceledMessageLink,
    timedoutTitle,
    timedoutMessage,
    timedoutMessageLink,
    back,
  ] = useCopy([
    "client.request.title",
    "client.request.message",
    "client.request.canceledTitle",
    "client.request.canceledMessage",
    "client.request.canceledMessageLink",
    "client.request.timedoutTitle",
    "client.request.timedoutMessage",
    "client.request.timedoutMessageLink",
    "client.request.back",
  ]);

  const title = () => {
    switch (request.status) {
      case Statuses.canceled:
        return canceledTitle + "...";
      case Statuses.timedout:
        return timedoutTitle + "...";
      default:
        return pendingTitle;
    }
  };

  const message = () => {
    switch (request.status) {
      case Statuses.timedout:
        return swapCopyVariables(timedoutMessage, {
          LINK: <Link href={questionnaireLink}>{timedoutMessageLink}</Link>,
        });
      case Statuses.canceled:
        return swapCopyVariables(canceledMessage, {
          LINK: <Link href={questionnaireLink}>{canceledMessageLink}</Link>,
        });
      default:
        return pendingMessage;
    }
  };

  return (
    <Wrapper>
      <Illustration src={BaristaGirl} alt="Request" />
      <Title>{title()}</Title>
      <Message>{message()}</Message>
      <BackLink
        onClick={() => {
          navigate(`/requests/${requestId}`);
        }}
      >
        <img src={backArrow} alt={back} />
        {back}
      </BackLink>
    </Wrapper>
  );
};

export default RequestPlaceholder;
