import { useState } from "react";
import styled from "styled-components";
import { Pill, InfoBox, ErrorBox } from "@sussex/react-kit/elements";
import SurveyTemplate from "./shared/SurveyTemplate";
import useCopy from "../../hooks/useCopy";
import LimitedTextBox from "../../components/LimitedTextBox";
import { useLocation } from "react-router-dom";
import { sendFeedback } from "../../metrics";
import { tokenResolveMatch } from "../../httpapi";

const Text = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

const Pills = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    grid-template-columns: 1fr 1fr;
  }
  margin-bottom: 16px;
`;

const DeclinedMatch = () => {
  const [message, setMessage] = useState("");
  const [continueMatching, setContinueMatching] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [error, setError] = useState(null);
  const [
    counterText,
    title,
    submitText,
    continueMessage,
    yes,
    no,
    declineReason,
    thanksContinueMatchingText,
    thanksStopMatchingText,
    errorText,
    duplicateText,
  ] = useCopy([
    "client.feedback.charactersCounter",
    "client.feedback.declinedTitle",
    "client.feedback.submitFeedback",
    "client.feedback.continueSearch",
    "client.feedback.continueSearch.yes",
    "client.feedback.continueSearch.no",
    "client.feedback.declineReason",
    "client.feedback.thanksContinueMatching",
    "client.feedback.thanksStopMatching",
    "client.confirmLanding.genericError",
    "client.confirmLanding.duplicateConfirmation",
  ]);
  const { state } = useLocation();
  const { token, matchId } = state;

  const resolve = () => {
    setProcessing(true);

    const postFeedback = async () => {
      const res = await sendFeedback({
        matchId,
        feedbackType: "match_refused",
        message,
      });
      if (!res.success) {
        console.log("could not send feedback message");
      }
    };

    const send = async () => {
      const res = await tokenResolveMatch({
        token,
        type: "match_refused",
        continueMatching,
      });
      if (res.success) {
        await postFeedback();
      } else {
        setError(res.conflict ? "conflict" : "generic");
      }

      setProcessing(false);
      setResolved(true);
    };

    send();
  };

  if (!token) {
    return null;
  }

  if (resolved) {
    return (
      <SurveyTemplate title={title}>
        {error ? (
          <ErrorBox>{error === "generic" ? errorText : duplicateText}</ErrorBox>
        ) : (
          <InfoBox>
            {continueMatching
              ? thanksContinueMatchingText
              : thanksStopMatchingText}
          </InfoBox>
        )}
      </SurveyTemplate>
    );
  }

  return (
    <SurveyTemplate
      title={title}
      onSubmit={resolve}
      submitText={submitText}
      submitDisabled={message.length > 500 || processing}
    >
      <Text>{continueMessage}</Text>
      <Pills>
        <Pill
          selected={continueMatching}
          onClick={() => setContinueMatching(true)}
        >
          {yes}
        </Pill>
        <Pill
          selected={!continueMatching}
          onClick={() => setContinueMatching(false)}
        >
          {no}
        </Pill>
      </Pills>
      <Text>{declineReason}</Text>
      <LimitedTextBox
        message={message}
        onChange={setMessage}
        maxLength={500}
        counterText={counterText}
      />
    </SurveyTemplate>
  );
};

export default DeclinedMatch;
