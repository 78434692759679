import styled from "styled-components";
import UnuthenticatedHeader from "../../components/Header/Header";
import AuthenticatedHeader from "../../components/Header/AuthenticatedHeader";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => theme.breakpoints[">tablet"]} {
    flex-direction: row;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Account for border radius: */
  padding-bottom: 10px;
  margin-bottom: -10px;
  background: linear-gradient(180deg, #ecf1fb 0%, rgba(245, 247, 249, 0) 100%),
    #ffffff;
  ${({ theme }) => theme.breakpoints[">tablet"]} {
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
  }
`;

const PageWrapper = ({ authenticated, children }) => {
  const Header = authenticated ? AuthenticatedHeader : UnuthenticatedHeader;
  return (
    <Wrapper>
      <ContentWrapper>
        <BackgroundWrapper>
          <Header />
        </BackgroundWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

export default PageWrapper;
