const baseGatewayURL = `${process.env.REACT_APP_GATEWAY_API_URL}`;

// What is life
const ISO86012004 = date => {
  const year = date.getUTCFullYear();
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);
  const hour = `0${date.getUTCHours()}`.slice(-2);
  const minute = `0${date.getUTCMinutes()}`.slice(-2);
  const second = `0${date.getUTCSeconds()}`.slice(-2);
  return `${year}${month}${day}T${hour}${minute}${second}Z`;
};

const calendarLinks = ({
  startTime,
  endTime,
  summary,
  details,
  method,
  locationUrl,
}) => {
  const startDate = new Date(startTime * 1000);
  const endDate = new Date(endTime * 1000);
  const startTimeICloud = startTime;
  const endTimeICloud = endTime;
  const startTimeGoogle = ISO86012004(startDate);
  const endTimeGoogle = ISO86012004(endDate);
  const startTimeOutlook = startDate.toISOString();
  const endTimeOutlook = endDate.toISOString();

  const location =
    method === "sessions" ? "Sessions" : method === "phone" ? "Phone Call" : "";

  const iCloud = () => {
    let url = `${baseGatewayURL}/ics?starttime=${startTimeICloud}&endtime=${endTimeICloud}&summary=${summary}&description=${details}`;
    if (locationUrl) {
      url = `${url}%20-%20${locationUrl}&url=${locationUrl}`;
    }
    if (location) {
      url = `${url}&location=${location}`;
    }
    return url;
  };

  const google = () => {
    let url = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startTimeGoogle}/${endTimeGoogle}&text=${summary}&details=${details}`;
    if (locationUrl) {
      url = `${url}%20-%20${locationUrl}`;
    }
    if (location) {
      url = `${url}&location=${location}`;
    }
    return url;
  };

  const outlook = () => {
    let url = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&allday=false&rru=addevent&startdt=${startTimeOutlook}&enddt=${endTimeOutlook}&subject=${summary}&body=${details}`;
    if (locationUrl) {
      url = `${url}%20-%20${locationUrl}`;
    }
    if (location) {
      url = `${url}&location=${location}`;
    }

    return url;
  };

  return {
    iCloud: iCloud(),
    Google: google(),
    Outlook: outlook(),
  };
};

export default calendarLinks;
