import { useContext } from "react";
import styled from "styled-components";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";
import useRewriteUrl from "../hooks/useRewriteUrl";
import SignIn from "../components/SignIn";
import Inbox from "../components/Inbox";
import RealtimeWebsocket from "../components/Websocket/RealtimeWebsocket";
import ErrorMessage from "../components/ErrorMessage";
import InactiveOverlay from "../components/InactiveOverlay";
import { UserContext } from "../providers/UserProvider";

const SignInPanel = styled(PanelWrapper)`
  min-height: 360px;
`;

export default function LandingView() {
  useRewriteUrl();
  const { signedIn, abandoned } = useContext(UserContext);

  if (signedIn) {
    return (
      <PageWrapper authenticated>
        <PanelWrapper>
          <ErrorMessage border />
          <Inbox />
          <RealtimeWebsocket />
          {abandoned ? <InactiveOverlay /> : null}
        </PanelWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper authenticated>
      <SignInPanel>
        <ErrorMessage border />
        <SignIn />
      </SignInPanel>
    </PageWrapper>
  );
}
