import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Loading } from "@sussex/react-kit/assets";
import { validateEmail } from "@sussex/react-kit/utils";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import { loginFailed } from "../../metrics";
import useCopy from "../../hooks/useCopy";
import {
  PrimaryHeading,
  Header,
  Form,
  Body,
  TextField,
  Submit,
  ErrorMessage,
  Footer,
} from "./Elements";
import OTP from "./OTP";
import arrow from "../../assets/back-arrow-full.svg";
import therapistImage from "../../assets/therapist.png";

const Image = styled.img`
  margin-right: 16px;
  width: 45px;
`;

const Center = styled.div`
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Back = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  padding: 20px 0;
  cursor: pointer;
  display: inline-flex;
  &:before {
    content: " ";
    background-image: url("${arrow}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 12px;
    width: 12px;
    height: 12px;
    height: 12px;
    margin-top: 2px;
  }
`;

const SignIn = () => {
  const [
    titleText,
    emailText,
    genericErrorText,
    invalidEmailText,
    signInText,
    backText,
  ] = useCopy([
    "client.signIn.title",
    "client.signIn.email",
    "client.signIn.error",
    "client.signIn.emailError",
    "client.signIn.signIn",
    "client.signIn.back",
  ]);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailValid = validateEmail(email);
  const theme = useTheme();

  const signIn = e => {
    e.preventDefault();
    setLoading(true);
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
    });
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
      Storage: MemoryStorage,
    });
    user.setAuthenticationFlowType("CUSTOM_AUTH");
    user.initiateAuth(authenticationDetails, {
      onFailure: err => {
        setLoading(false);
        loginFailed({ reason: err.message });
        setLoginError(genericErrorText);
        console.error(err.message || JSON.stringify(err));
      },
      customChallenge: () => {
        setLoading(false);
        setCognitoUser(user);
      },
    });
  };

  const checkEmail = () => {
    setEmailError(emailValid ? false : invalidEmailText);
  };

  const handleEmailChange = ({ target }) => {
    setEmailError(false);
    setEmail(target.value);
  };

  if (cognitoUser) {
    return (
      <>
        <Header>
          <Image src={therapistImage} alt={titleText} />
          <PrimaryHeading>{titleText}</PrimaryHeading>
        </Header>
        <Body>
          <OTP email={email} cognitoUser={cognitoUser} />
        </Body>
        <Footer>
          <Back onClick={() => setCognitoUser(null)}>{backText}</Back>
        </Footer>
      </>
    );
  }

  return (
    <Form onSubmit={signIn}>
      <Header>
        <Image src={therapistImage} alt={titleText} />
        <PrimaryHeading>{titleText}</PrimaryHeading>
      </Header>
      <Body>
        <Center>
          {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
          {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          <TextField
            label={emailText}
            value={email}
            type="email"
            onChange={handleEmailChange}
            onBlur={checkEmail}
          />
        </Center>
      </Body>
      <Footer>
        <Center>
          <Submit type="submit" disabled={!emailValid} onClick={signIn}>
            {loading ? (
              <Loading innerColor={theme.colors.primaryBlue} />
            ) : (
              signInText
            )}
          </Submit>
        </Center>
      </Footer>
    </Form>
  );
};

export default SignIn;
