import { useState, useEffect } from "react";
import styled from "styled-components";
import { InfoBox, ErrorBox } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import SurveyTemplate from "./shared/SurveyTemplate";
import { tokenResolveMatch } from "../../httpapi";
import { Loading } from "@sussex/react-kit/assets";
import Header from "../../components/Header";
import HomeLink from "./shared/HomeLink";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  ${({ theme }) => theme.breakpoints[">tablet"]} {
    flex-direction: row;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Account for border radius: */
  padding-bottom: 10px;
  margin-bottom: -10px;
  background: linear-gradient(180deg, #ecf1fb 0%, rgba(245, 247, 249, 0) 100%),
    #ffffff;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
  }
`;

const PanelWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  background: white;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex: 1 0 auto;
    margin: 0 auto;
    width: calc(60%);
    min-width: 800px;
    max-width: 900px;
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-radius: 16px;
    min-height: 600px;
  }
`;

const MatchFound = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, successMessage, genericError, duplicateText] = useCopy([
    "client.feedback.introductionCall.title",
    "client.feedback.thankYou.success",
    "client.confirmLanding.genericError",
    "client.confirmLanding.duplicateConfirmation",
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!token) {
      setError("generic");
      setLoading(false);
      return;
    }

    const send = async () => {
      const res = await tokenResolveMatch({
        token,
        type: "match_found",
        continueMatching: false,
      });
      if (!res.success) {
        setError(res.conflict ? "conflict" : "generic");
      }
      setLoading(false);
    };

    send();
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <ContentWrapper>
          <BackgroundWrapper>
            <Header />
          </BackgroundWrapper>
          <PanelWrapper>
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          </PanelWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <SurveyTemplate title={title}>
      {error ? (
        <ErrorBox>
          {error === "generic" ? genericError : duplicateText}
        </ErrorBox>
      ) : (
        <InfoBox>{successMessage}</InfoBox>
      )}
      <HomeLink />
    </SurveyTemplate>
  );
};

export default MatchFound;
