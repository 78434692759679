import styled from "styled-components";
import { Loading, Error } from "@sussex/react-kit/assets";
import useCopy from "../../hooks/useCopy";

const ConfirmWrapper = styled.div``;

const ConfirmOverlay = styled.div`
  position: absolute;
  background: white;
  z-index: 10;
  opacity: 0.75;
  inset: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    top: ${({ theme }) => theme.headerHeight + "px"};
  }

  ${({ theme }) => theme.breakpoints[">tablet"]} {
    top: 0;
  }
`;

const ConfirmBox = styled.div`
  position: absolute;
  z-index: 11;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: min(calc(100% - 80px), 600px);
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 16px;
  background: white;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
`;

const ConfirmTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const ConfirmPrompt = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin-bottom: 16px;
`;

const ConfirmIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.lightYellow};
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ConfirmButton = styled.button`
  appearance: none;
  background: white;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1;
  padding: 20px 0;
  flex: 1;

  &:hover {
    background: ${({ theme }) => theme.colors.light};
  }
  &:disabled {
    cursor: default;
  }
`;

ConfirmButton.defaultProps = {
  type: "button",
};

const ConfirmationPrompt = ({
  title,
  prompt,
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  loading,
}) => {
  const [defaultTitle, defaultYes, defaultNo] = useCopy([
    "client.cancelConfirm.title",
    "client.cancelConfirm.yes",
    "client.cancelConfirm.no",
  ]);

  const titleText = title || defaultTitle;
  const yesText = confirmText || defaultYes;
  const noText = cancelText || defaultNo;

  return (
    <ConfirmWrapper>
      <ConfirmOverlay />
      <ConfirmBox>
        <ConfirmTitle>
          <ConfirmIcon>
            <Error color="#D97706" />
          </ConfirmIcon>
          {titleText}
        </ConfirmTitle>
        <ConfirmPrompt>{prompt}</ConfirmPrompt>
        <ConfirmButtonWrapper>
          <ConfirmButton onClick={onCancel} disabled={loading}>
            {noText}
          </ConfirmButton>
          <ConfirmButton onClick={onConfirm} disabled={loading}>
            {loading ? <Loading /> : yesText}
          </ConfirmButton>
        </ConfirmButtonWrapper>
      </ConfirmBox>
    </ConfirmWrapper>
  );
};

export default ConfirmationPrompt;
