import { useState } from "react";
import styled from "styled-components";
import useCopy from "../../../hooks/useCopy";
import RadioOption from "../../../components/RadioOption";

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.light};
  padding: 24px;
  margin-top: 12px;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ContinuePanel = ({ onChange }) => {
  const [continueText, yes, no] = useCopy([
    "client.feedback.continueSearch",
    "client.feedback.continueSearch.yes",
    "client.feedback.continueSearch.no",
  ]);
  const [shouldContinue, setShouldContinue] = useState(true);
  const _setShouldContinue = val => {
    setShouldContinue(val);
    onChange(val);
  };
  return (
    <Wrapper>
      <Title>{continueText}</Title>
      <RadioWrapper>
        <RadioOption
          onClick={() => _setShouldContinue(true)}
          label={yes}
          checked={shouldContinue}
        />
        <RadioOption
          onClick={() => _setShouldContinue(false)}
          label={no}
          checked={!shouldContinue}
        />
      </RadioWrapper>
    </Wrapper>
  );
};
export default ContinuePanel;
