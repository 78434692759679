import { useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import closeIcon from "../../assets/close.svg";

const TRANSITION_TIME = 400;
const TRANSITION_NAME = "slideout";

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 24, 39, 0.5);
  z-index: 10;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  overflow: hidden;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 8px 8px 0px 0px;

  transition: transform ${TRANSITION_TIME}ms ease-in-out;
  transform: translateY(100%);

  &.${TRANSITION_NAME}-enter {
    transform: translateY(100%);
  }

  &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-enter-done {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit-active {
    transform: translateY(100%);
  }

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    inset: 16px;
    width: calc(100% - 32px);
    border-radius: 16px;
    transform: translateX(100%);

    &.${TRANSITION_NAME}-enter {
      transform: translateX(100%);
    }

    &.${TRANSITION_NAME}-enter-active {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-enter-done {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit-active {
      transform: translateX(100%);
    }
  }

  ${({ theme }) => theme.breakpoints[">tablet"]} {
    top: 16px;
    bottom: 16px;
    right: 0;
    margin-right: 16px;
    left: auto;
    width: 600px;
    border: none;
    box-shadow: 0px 0px 30px rgba(31, 41, 55, 0.15);
    transform: translateX(100%);

    &.${TRANSITION_NAME}-enter {
      transform: translateX(100%);
    }

    &.${TRANSITION_NAME}-enter-active {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-enter-done {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit-active {
      transform: translateX(100%);
    }
  }
`;

const Header = styled.div`
  width: 100%;
  top: 0;
  z-index: 10;
  position: ${({ title }) => (title ? "static" : "absolute")};
  border-bottom: ${({ title, theme }) =>
    title ? `1px solid ${theme.colors.border}` : "none"};
`;

const TitleWrapper = styled.div`
  padding: 16px;
  height: 55px;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.medGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 24px;
  right: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  &:after {
    content: "";
    background-image: url("${closeIcon}");
    background-repeat: no-repeat;
    background-position: center center;
    inset: 0;
    background-size: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Footer = styled.div`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const Slideout = ({ active, title, subtitle, onClose, footer, children }) => {
  const nodeRef = useRef(null);

  return (
    <>
      {active && <Overlay onClick={onClose} />}
      <CSSTransition
        nodeRef={nodeRef}
        in={active}
        timeout={TRANSITION_TIME}
        classNames={TRANSITION_NAME}
        unmountOnExit
      >
        <Panel ref={nodeRef}>
          <Header title={title}>
            {title && (
              <TitleWrapper>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </TitleWrapper>
            )}
            <CloseButton type="button" onClick={onClose} />
          </Header>
          <Content>{children}</Content>
          {footer && <Footer>{footer}</Footer>}
        </Panel>
      </CSSTransition>
    </>
  );
};

export default Slideout;
