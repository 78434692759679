import { useContext } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { Loading } from "@sussex/react-kit/assets";

import { RequestContext } from "../../providers/RequestProvider";
import InboxProvider from "../../providers/InboxProvider";

import MatchDetail from "../MatchDetail";
import RequestPlaceholder from "./RequestPlaceholder";
import Slideouts from "./Slideouts";
import Messages from "./Messages";
import RequestActions from "./RequestActions";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: flex;
  }
  ${({ theme }) => theme.breakpoints[">tablet"]} {
    height: calc(100vh - 132px);
    border-radius: 16px;
  }
`;

const MessagesWrapper = styled.div`
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  padding: 16px;
  position: relative;
  overflow: hidden;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 42%;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const DetailWrapper = styled.div`
  width: 58%;
  position: relative; // needed to contain overlay

  ${({ theme }) => theme.breakpoints["mobile"]} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

const Inbox = () => {
  const { requestId, matchId } = useParams();
  const { requests, loading } = useContext(RequestContext);

  const activeRequest = requests.find(r => r.id === requestId) || requests[0];
  const matches = activeRequest?.matches || [];
  const activeMatch = matches.find(i => i.id === matchId);

  return (
    <InboxProvider match={activeMatch}>
      <Wrapper>
        {loading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <>
            <MessagesWrapper>
              <ListWrapper>
                <Messages activeRequest={activeRequest} requests={requests} />
              </ListWrapper>
              <RequestActions activeRequest={activeRequest} />
            </MessagesWrapper>
            <DetailWrapper active={!!matchId}>
              {activeMatch ? (
                <MatchDetail match={activeMatch} />
              ) : (
                <RequestPlaceholder request={activeRequest} />
              )}
            </DetailWrapper>
          </>
        )}
      </Wrapper>
      <Slideouts request={activeRequest} match={activeMatch} />
    </InboxProvider>
  );
};

export default Inbox;
