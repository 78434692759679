import { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../providers/UserProvider";
import useCopy from "../../hooks/useCopy";
import Header from "./Header";

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  margin: 0;
  padding: 0;
`;

const AuthenticatedHeader = () => {
  const { signOut, signedIn } = useContext(UserContext);
  const [logoutText] = useCopy(["client.panelHeader.logoutLink"]);

  const userInfo = () => {
    return (
      <UserInfo onClick={signOut}>
        {signedIn && <Text>{logoutText}</Text>}
      </UserInfo>
    );
  };

  return <Header right={userInfo} />;
};

export default AuthenticatedHeader;
