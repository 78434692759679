// This provider is relatively narrow in scope and I can see it being expanded
// upon in the future. It's purpose (currently) is limited to effectively
// trying to mimic link tracking. We are attempting to record the source
// (email vs sms) of incoming traffic, along with some additional context about
// the incoming request (ie. which iteration of a given email / sms brought a
// user to this page). This meets the current set of business requirements just
// fine, however much could be improved with better link tracking I feel.
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { initialPageLoad } from "../../metrics";

// These are the whitelisted query parameters that we will send in the metadata
// payload of this event.
const contextParams = [
  "template",
  "requestId",
  "matchId",
  "iteration",
  "source",
];

const PageMetricProvider = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const buildPayload = () => {
      // We always want to send through the current URL in the payload.
      const payload = {
        url: window.location.href,
      };

      contextParams.forEach(i => {
        const v = searchParams.get(i);
        if (v) {
          payload[i] = v;
          searchParams.delete(i);
        }
      });
      return payload;
    };
    // Trigger this metric only if there is a source parameter
    if (searchParams.get("source")) {
      initialPageLoad(buildPayload());
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return null;
};

export default PageMetricProvider;
