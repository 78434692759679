import {
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Heartbeat from "../Heartbeat";
import { UserContext } from "../../providers/UserProvider";

const heartbeatInterval = 20000;
const getCurrentTime = () => new Date().getTime();

const Websocket = ({
  url,
  onError,
  onMessage,
  onConnect = () => {},
  onDisconnect = () => {},
}) => {
  const [connect, setConnect] = useState(true);
  const lastPongDate = useRef(0);
  const lastJsonMessageProcessed = useRef(null);
  const { abandoned } = useContext(UserContext);

  const reconnect = useCallback(() => {
    setConnect(false);

    setTimeout(() => {
      setConnect(true);
    }, 1000);
  }, []);

  const opts = useMemo(
    () => ({
      share: true,
      filter: event => {
        try {
          const parsed = JSON.parse(event.data);
          return !!parsed.body || !!parsed.message;
        } catch (err) {
          console.error("Failed to parse websocket message", event?.data, err);
          return false;
        }
      },
      shouldReconnect: () => true,
      onOpen: onConnect,
      onClose: onDisconnect,
      onError,
    }),
    [onError, onConnect, onDisconnect],
  );

  const { readyState, lastJsonMessage, sendJsonMessage } = useWebSocket(
    url,
    opts,
    connect,
  );

  // Disconnect websocket if user abandons site
  useEffect(() => {
    if (abandoned) {
      setConnect(false);
    }
  }, [abandoned]);

  const heartbeatFunction = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      const lastPingDate = getCurrentTime();
      sendJsonMessage({ message: "ping" });

      setTimeout(() => {
        if (lastPongDate.current < lastPingDate) {
          onError(new Error("websocket not receiving messages"));
          reconnect();
        }
      }, heartbeatInterval / 4);
    }
  }, [onError, readyState, sendJsonMessage, reconnect]);

  // Handle messages
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }
    if (lastJsonMessageProcessed.current === lastJsonMessage) {
      return;
    }

    if (lastJsonMessage.message === "pong") {
      lastPongDate.current = getCurrentTime();
      return;
    }

    onMessage(lastJsonMessage);
    lastJsonMessageProcessed.current = lastJsonMessage;
  }, [onMessage, lastJsonMessage]);

  return (
    <Heartbeat
      heartbeatFunction={heartbeatFunction}
      heartbeatInterval={heartbeatInterval}
    />
  );
};

export default Websocket;
