import { useEffect, useState, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Loading as Spinner } from "@sussex/react-kit/assets";
import { getMatchedPreferences } from "@sussexdir/match-utils";
import useCopy from "../../hooks/useCopy";
import { AttributeContext } from "../../providers/AttributeProvider";

const iframeDomain = `${process.env.REACT_APP_PROFILE_IFRAME_URL}`;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 5px);
  position: relative;
`;

const Details = styled.iframe`
  border: 0;
  margin: auto 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0;
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Profile = ({ match }) => {
  const { attributes } = useContext(AttributeContext);
  const [loading, setLoading] = useState(true);
  const [
    insuranceTemplate,
    languagesTemplate,
    sexualityServedTemplate,
    religionServedTemplate,
    ethnicityServedTemplate,
    onlineAndInPersonText,
    onlineText,
    inPersonText,
    feeText,
    feeFlexibleText,
  ] = useCopy([
    "client.preferenceStatement.insurance",
    "client.preferenceStatement.languages",
    "client.preferenceStatement.sexualityServed",
    "client.preferenceStatement.religionServed",
    "client.preferenceStatement.ethnicityServed",
    "client.preferenceStatement.onlineAndInPerson",
    "client.preferenceStatement.online",
    "client.preferenceStatement.inPerson",
    "client.preferenceStatement.fee",
    "client.preferenceStatement.feeFlexible",
    "client.actions.confirm",
    "client.actions.refuse",
  ]);
  const {
    colors: { default: defaultColor, border },
  } = useTheme();

  let matchedPreferences;
  try {
    matchedPreferences = getMatchedPreferences(
      attributes,
      match.preferences,
      match.therapist,
      {
        insuranceTemplate,
        languagesTemplate,
        sexualityServedTemplate,
        religionServedTemplate,
        ethnicityServedTemplate,
        onlineAndInPersonText,
        onlineText,
        inPersonText,
        feeText,
        feeFlexibleText,
      },
      swapCopyVariables,
    );
  } catch (e) {
    console.error("Failed to get tags", e);
  }

  useEffect(() => {
    const criteria = matchedPreferences.map(t => ({
      label: t.text,
      disabled: !!t.inactive,
    }));
    const cw = document.querySelector("#profileViewer")?.contentWindow;
    if (!cw) {
      return;
    }
    const listenForMessage = message => {
      if (message?.source !== cw) {
        return;
      }
      if (message?.data?.event === "directory.mounted") {
        setLoading(false);
        cw.postMessage(
          {
            method: "setMatchingCriteria",
            criteria: criteria,
          },
          iframeDomain,
        );
      }
    };

    window.addEventListener("message", listenForMessage);
    return () => {
      window.removeEventListener("message", listenForMessage);
    };
  }, [matchedPreferences]);

  const iframeURL = `${iframeDomain}/${match.therapist?.countryCode}/${match.therapist?.context}/match-view/${match.profileId}`;

  return (
    <Wrapper>
      {loading && (
        <Loading>
          <Spinner size={24} outerColor={border} innerColor={defaultColor} />
        </Loading>
      )}
      <Details
        active={true}
        src={iframeURL}
        id="profileViewer"
        referrerpolicy="origin"
      />
    </Wrapper>
  );
};

export default Profile;
