const MaleFace = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_905_27331)">
        <path
          d="M13 6.5C13 9.814 10.761 13 8 13C5.239 13 3 9.814 3 6.5"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 13V12.418V13.757C5.99949 14.1064 5.87702 14.4446 5.65375 14.7133C5.43047 14.9821 5.12038 15.1644 4.777 15.229L2 15.75"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13V12.418V13.757C10.0005 14.1064 10.123 14.4446 10.3463 14.7133C10.5695 14.9821 10.8796 15.1644 11.223 15.229L14 15.75"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99989 1C5.23889 1 2.49989 1.5 2.99989 6.5C2.99989 6.618 3.00889 6.736 3.01489 6.854C3.05027 6.35134 3.27465 5.88071 3.6429 5.53676C4.01116 5.19281 4.49599 5.00103 4.99989 5H8.49989C9.03032 5 9.53903 4.78929 9.9141 4.41421C10.2892 4.03914 10.4999 3.53043 10.4999 3V3.5C10.5006 4.20844 10.752 4.89377 11.2096 5.43461C11.6672 5.97546 12.3014 6.33693 12.9999 6.455C13.4809 1.5 10.7529 1 7.99989 1Z"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_27331">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MaleFace;
