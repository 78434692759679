import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const MethodIntro = ({ match }) => {
  const [video, phone] = useCopy([
    "client.appointmentConfirmation.video",
    "client.appointmentConfirmation.phone",
  ]);

  const isVideo =
    match.appointmentMethod === "sessions" ||
    (match.appointmentMethod === "other" && match.otherVideoDetail);

  return <Wrapper>{isVideo ? video : phone}</Wrapper>;
};

export default MethodIntro;
