import { useState, createContext, useCallback, useEffect } from "react";
import { CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { useIsActive } from "@sussex/react-kit/hooks";
import { logoutUser, getCurrentUser } from "../../httpapi";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";

const refreshTokens = ({ email, refreshToken }) => {
  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };
  const cognitoUser = new CognitoUser(userData);
  const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
  return new Promise((resolve, reject) =>
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    }),
  );
};

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [signedIn, setSignedIn] = useState(null);
  const [abandoned, setAbandoned] = useState(false);
  const [verified, setVerified] = useState(null);
  const navigate = useNavigate();
  const { lastActivityDate, active } = useIsActive();

  const signOut = useCallback(async () => {
    const { success } = await logoutUser();
    if (success) {
      setSignedIn(false);
      setUser({});
      navigate("/");
    }
  }, [navigate]);

  const signInUser = useCallback(async u => {
    if (!u) {
      return;
    }
    // don't save tokens in app
    u.idToken = undefined;
    u.refreshToken = undefined;
    setUser(u);
    setSignedIn(true);
  }, []);

  const fetchCurrentUser = useCallback(async () => {
    const u = await getCurrentUser();
    if (!u) {
      return;
    }
    try {
      const tokens = await refreshTokens(u);
      u.idToken = tokens.idToken.jwtToken;
      signInUser(u);
    } catch (e) {
      await signOut();
    }
  }, [signInUser, signOut]);

  useEffect(() => {
    if (active || abandoned) {
      return;
    }

    // inactive gets set 15 seconds after last activity
    const timeoutID = setTimeout(() => setAbandoned(true), 1000 * 60 * 30);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [lastActivityDate, abandoned, active]);

  useEffect(() => {
    if (!user.uuid || abandoned) {
      return;
    }
    const timeoutID = setTimeout(fetchCurrentUser, 30 * 60 * 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [user, fetchCurrentUser, abandoned]);

  return (
    <UserContext.Provider
      value={{
        fetchCurrentUser,
        signInUser,
        signOut,
        abandoned,
        user,
        verified,
        setVerified,
        signedIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
