import { useContext, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { Loading as Spinner } from "@sussex/react-kit/assets";
import { AppStateContext } from "../../providers/AppStateProvider";
import { ErrorContext } from "../../providers/ErrorProvider";
import useCopy from "../../hooks/useCopy";
import PageWrapper from "../../pages/shared/PageWrapper";
import PanelWrapper from "../../pages/shared/PanelWrapper";
import ErrorMessage from "../../components/ErrorMessage";

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  flex: 1;
`;

const Init = ({ children, theme }) => {
  const { loading, error } = useContext(AppStateContext);
  const { setError } = useContext(ErrorContext);
  const [title, button, body] = useCopy([
    "client.error.title",
    "client.error.retry",
    "client.init.errorBody",
  ]);
  const copy = {
    title: title || "Oops! Something went wrong",
    body:
      body || "There seems to be an issue loading this page. Please try again.",
    button: button || "Retry",
  };

  useEffect(() => {
    if (error) {
      setError({
        title: copy.title,
        message: copy.body,
        buttonText: copy.button,
        onClick: () => window.location.reload(),
      });
    }
  }, [error, setError, copy.body, copy.title, copy.button]);

  if (!(loading || error)) {
    return children;
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <ErrorMessage />
        {loading && (
          <LoadingWrapper>
            <Spinner size={24} />
          </LoadingWrapper>
        )}
      </PanelWrapper>
    </PageWrapper>
  );
};

export default withTheme(Init);
