import { useContext } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Button } from "@sussex/react-kit/elements";
import { getDisplayPhoneNumber } from "@sussex/react-kit/utils";
import { Statuses, StatusReasons } from "@sussexdir/match-utils";
import { ChatBubble } from "@sussex/react-kit/message-center";
import useCopy from "../../hooks/useCopy";
import AppointmentCard from "../AppointmentCard";
import { InboxContext } from "../../providers/InboxProvider";
import { viewProfileClicked } from "../../metrics";

const questionnaireLink = `${process.env.REACT_APP_DIRECTORY_URL}/match`;

const Text = styled.div`
  margin: 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1.5;
  text-align: center;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const PhoneLink = styled(Link)`
  white-space: nowrap;
`;

const LinkButton = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;

const LinkText = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const Section = styled.div`
  padding: 16px 48px;
`;

const StatusMessage = ({ match }) => {
  const { setProfilePanelActive } = useContext(InboxContext);
  const [
    appointmentDetailsTitle,
    acceptedIntro,
    acceptedIntroProfile,
    confirmedIntro,
    confirmedIntroPhone,
    confirmedIntro2,
    confirmedIntro2ProfileLink,
    scheduledChatUnlockedText,
    scheduledChatUnlockedPhoneText,
    refusedIntro,
    timedOutTitle,
    timedOutCta,
    canceledBody,
    canceledLink,
    closedIntro,
    closedIntro2,
    closedPassedIntro,
  ] = useCopy([
    "client.clientMessage.appointmentDetails.title",
    "client.matchAccepted.intro",
    "client.matchAccepted.intro.profileLink",
    "client.matchConfirmed.intro",
    "client.matchConfirmed.intro.phone",
    "client.matchConfirmed.intro2",
    "client.matchConfirmed.intro2.profileLink",
    "client.matchScheduled.chatUnlocked",
    "client.matchScheduled.chatUnlockedPhone",
    "client.matchRefused.intro",
    "client.matchTimedOut.intro",
    "client.matchTimedOut.cta",
    "client.systemMessages.statusCanceled.body",
    "client.systemMessages.statusCanceled.link",
    "client.matchClosed.therapistUnavailable.intro",
    "client.matchClosed.therapistUnavailable.intro2",
    "client.matchPassed.intro",
  ]);

  let status = match.status;
  if (match.status === Statuses.resolved) {
    // once a match is resolved, we want to still show the status message of the previous status
    status = match.previousStatus;
  }

  const openProfile = () => {
    viewProfileClicked({
      itemId: match.id,
      profileId: match.profileId,
    });
    return setProfilePanelActive(true);
  };

  const getContent = () => {
    switch (status) {
      case Statuses.accepted:
        return (
          <>
            <Section>
              <Text>
                <span>
                  {swapCopyVariables(acceptedIntro, {
                    THERAPIST_NAME: (
                      <LinkButton onClick={openProfile}>
                        {match.therapist.fullName}
                      </LinkButton>
                    ),
                    PROFILE_LINK: (
                      <LinkButton onClick={openProfile}>
                        {acceptedIntroProfile}
                      </LinkButton>
                    ),
                  })}
                </span>
              </Text>
            </Section>
            {match.additionalInformation ? (
              <>
                <ChatBubble
                  isSender={false}
                  message={match.additionalInformation}
                />
              </>
            ) : null}
          </>
        );

      case Statuses.confirmed:
        const intro = match.therapist?.phoneNumber?.number
          ? swapCopyVariables(confirmedIntroPhone, {
              NAME: (
                <LinkButton onClick={openProfile}>
                  {match.therapist.fullName}
                </LinkButton>
              ),
              NUMBER: (
                <PhoneLink
                  href={getDisplayPhoneNumber(
                    {
                      number: match.therapist.phoneNumber.number,
                      extension: match.therapist.phoneNumber.extension,
                    },
                    true,
                  )}
                >
                  {getDisplayPhoneNumber({
                    number: match.therapist.phoneNumber.number,
                    extension: match.therapist.phoneNumber.extension,
                    countryCode: match.therapist.countryCode,
                  })}
                </PhoneLink>
              ),
            })
          : swapCopyVariables(confirmedIntro, {
              NAME: (
                <LinkButton onClick={openProfile}>
                  {match.therapist.fullName}
                </LinkButton>
              ),
            });

        return (
          <>
            <Section>
              <Text>{intro}</Text>
              <Text>
                {swapCopyVariables(confirmedIntro2, {
                  PROFILE_LINK: (
                    <LinkText onClick={openProfile}>
                      {confirmedIntro2ProfileLink}
                    </LinkText>
                  ),
                })}
              </Text>
            </Section>
            {match.additionalInformation ? (
              <ChatBubble
                isSender={false}
                message={match.additionalInformation}
              />
            ) : null}
          </>
        );

      case Statuses.scheduled:
        return (
          <>
            <Section>
              <Text>
                {swapCopyVariables(appointmentDetailsTitle, {
                  THERAPIST_NAME: (
                    <LinkButton onClick={openProfile}>
                      {match.therapist.fullName}
                    </LinkButton>
                  ),
                })}
              </Text>
              <AppointmentCard match={match} />
              <Text>
                {match.therapist?.phoneNumber?.number
                  ? swapCopyVariables(scheduledChatUnlockedPhoneText, {
                      NUMBER: (
                        <PhoneLink
                          href={getDisplayPhoneNumber(
                            {
                              number: match.therapist.phoneNumber.number,
                              extension: match.therapist.phoneNumber.extension,
                            },
                            true,
                          )}
                        >
                          {getDisplayPhoneNumber({
                            number: match.therapist.phoneNumber.number,
                            extension: match.therapist.phoneNumber.extension,
                            countryCode: match.therapist.countryCode,
                          })}
                        </PhoneLink>
                      ),
                    })
                  : scheduledChatUnlockedText}
              </Text>
            </Section>
          </>
        );

      case Statuses.closed:
        if (match.statusReason === StatusReasons.passed) {
          return (
            <Section>
              <Text>
                {swapCopyVariables(closedPassedIntro, {
                  THERAPIST_NAME: (
                    <LinkButton onClick={openProfile}>
                      {match.therapist.fullName}
                    </LinkButton>
                  ),
                })}
              </Text>
            </Section>
          );
        }
        return (
          <Section>
            <Text>
              {swapCopyVariables(closedIntro, {
                THERAPIST_NAME: (
                  <LinkButton onClick={openProfile}>
                    {match.therapist.fullName}
                  </LinkButton>
                ),
              })}
            </Text>
            <Text>{closedIntro2}</Text>
          </Section>
        );

      case Statuses.refused:
        return (
          <Section>
            <Text>{refusedIntro}</Text>
          </Section>
        );

      case Statuses.timedout:
        return (
          <Section>
            <Text>{timedOutTitle}</Text>
            <Button as="a" href={process.env.REACT_APP_MATCH_URL} full>
              {timedOutCta}
            </Button>
          </Section>
        );

      case Statuses.canceled:
        return (
          <Section>
            <Text>
              {swapCopyVariables(canceledBody, {
                LINK: <Link href={questionnaireLink}>{canceledLink}</Link>,
              })}
            </Text>
          </Section>
        );

      default:
        return null;
    }
  };

  return getContent();
};

export default StatusMessage;
