import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { Anchor } from "@sussex/react-kit/elements";

const Link = styled(Anchor)`
  font-family: ${({ theme }) => theme.fonts.primary};
  text-decoration: underline;
`;

const LoginLink = () => {
  const [linkText] = useCopy(["client.confirmLanding.loginLinkText"]);
  return <Link href="https://client.psychologytoday.com">{linkText}</Link>;
};

export default LoginLink;
