import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { ChatFooter as Input } from "@sussex/react-kit/message-center";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { sendMessage } from "../../httpapi";
import { chatMessageSent } from "../../metrics";
import { RequestContext } from "../../providers/RequestProvider";
import useCopy from "../../hooks/useCopy";
import { isChatEnabled } from "../../utils";
import { Statuses } from "@sussexdir/match-utils";

const ChatInput = ({ match }) => {
  const {
    confirmMatch,
    addPendingMessage,
    confirmPendingMessage,
    refusePendingMessage,
  } = useContext(RequestContext);
  const [isSending, setIsSending] = useState(false);
  const [inputPlaceholder, inputPlaceholderNoName, tooltip] = useCopy([
    "client.matchChat.inputPlaceholder",
    "client.matchChat.inputPlaceholderNoName",
    "client.matchChat.disabledTooltip",
  ]);

  const chatLocked = !isChatEnabled(match);

  const handleSend = async message => {
    setIsSending(true);
    const tmpId = "tmp-" + uuidv4();
    addPendingMessage(match, tmpId, message);
    // Confirm message first before sending
    if (match.status === Statuses.accepted) {
      await confirmMatch(match);
    }
    const res = await sendMessage({ itemId: match.id, message });
    if (res.success) {
      confirmPendingMessage(match, tmpId, res.id);
      chatMessageSent({
        itemId: match.id,
      });
    } else {
      console.error(res.error);
      refusePendingMessage(match, tmpId);
    }
    setIsSending(false);
  };

  const getPlaceholder = () => {
    if (!match?.name) {
      return inputPlaceholderNoName;
    }
    return swapCopyVariables(inputPlaceholder, {
      NAME: match?.title,
    }).join("");
  };

  return (
    <Input
      placeholder={getPlaceholder()}
      locked={chatLocked}
      disabled={isSending}
      tooltip={chatLocked ? tooltip : undefined}
      onSend={handleSend}
      autoFocus={!chatLocked}
    />
  );
};

export default ChatInput;
