import {
  useContext,
  useEffect,
  createContext,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { Statuses, RequestStatuses } from "@sussexdir/match-utils";
import {
  getTherapist,
  getRequests,
  getMatches,
  getMessages,
  readMessages,
  viewMatch,
  cancelRequest as _cancelRequest,
  getMatch,
  confirmMatch as _confirmMatch,
  resumeSearch,
} from "../../httpapi";
import { getPhotoUrl } from "../../utils";
import { UserContext } from "../UserProvider";
import useCopy from "../../hooks/useCopy";
import blurredProfile from "../../assets/blurred-therapist.png";
import { ErrorContext } from "../ErrorProvider";

const maxMessageResponseLength = 10;

export const RequestContext = createContext(null);

const therapists = {};

const getTherapistInfo = async id => {
  if (therapists[id]) {
    return therapists[id];
  }
  const therapist = await getTherapist(id);
  if (therapist.success) {
    therapist.photoUrl = getPhotoUrl(id);
    therapists[id] = therapist;
  } else {
    console.error("Failed to get therapist", id, therapist.error);
  }
  return therapist;
};

const isActiveRequest = r =>
  [RequestStatuses.open, RequestStatuses.exhausted].includes(r.status);

const isActiveMatch = m =>
  [Statuses.accepted, Statuses.confirmed, Statuses.scheduled].includes(
    m.status,
  ) ||
  (m.status === Statuses.resolved &&
    [Statuses.confirmed, Statuses.scheduled].includes(m.previousStatus));

const isPartialMatch = m => m.requestId && !m.clientUuid;

const unifyItems = async (items, requestTitle) => {
  const unifiedItems = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (!item.type) {
      if (item.requestId) {
        // item is a match
        if (!isPartialMatch(item)) {
          item.type = "match";

          const therapist = await getTherapistInfo(item.profileId);
          if (therapist) {
            item.title = therapist.fullName;
            item.therapist = therapist;
            item.photo = therapist.photoUrl;
          }
        }
      } else {
        // item is a request
        item.type = "request";
        item.title = requestTitle;
        item.photo = blurredProfile;
      }
    }

    if (item.type === "request") {
      item.date = item.createdAt * 1000;
      item.active = isActiveRequest(item);

      const relatedMatches = allItems => {
        return allItems
          .filter(m => m.requestId === item.id)
          .map(m => {
            m.date = m.lastActivityTimestamp / (1000 * 1000);
            m.active = isActiveMatch(m);
            if (!m.location) {
              // get contact details from the request if they are not present
              const req = allItems.find(r => r.id === m.requestId);

              if (req) {
                m.name = req.name;
                m.email = req.email;
                m.phoneNumber = req.phoneNumber;
                m.location = req.location;
              }
            }
            return m;
          });
      };

      item.matches = relatedMatches(items);
      unifiedItems.push(item);
    }
  }

  return unifiedItems;
};

// Filter duplicates and sort by id asc
const filterAndSortMessages = messages =>
  messages
    .filter((m, i, a) => {
      if (!m?.id) {
        return false;
      }
      return a.findIndex(m2 => m2.id === m.id) === i;
    })
    .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));

const RequestProvider = ({ children }) => {
  const [rawItems, setRawItems] = useState(null);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, signedIn } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);
  const [matchRequest, listError, cancelError, confirmError, resumeError] =
    useCopy([
      "client.matchRequest",
      "client.list.error",
      "client.cancelConfirm.error",
      "client.matchSuccess.declineError",
      "client.matchSuccess.proposeChangeError",
      "client.continueSearchingConfirm.error",
    ]);

  // we need to use a useLayoutEffect instead of a useEffect here
  // to ensure that effects are executed synchronously when rawItems
  // changes, because we update rawItems from here as well in some cases
  useLayoutEffect(() => {
    if (!rawItems) {
      return;
    }

    let canceled = false;
    const cancelEffect = () => {
      canceled = true;
    };

    // check if there are items with partial data and try to populate them
    const partialMatches = rawItems.filter(
      item => isPartialMatch(item) && !item.populated,
    );

    if (partialMatches.length > 0) {
      const fullItems = rawItems.filter(
        item => !partialMatches.find(it => it.id === item.id),
      );
      const populate = async () => {
        const populateItem = async item => {
          item.populated = true;
          const res = await getMatch(item.requestId, item.id);
          if (res.success) {
            Object.assign(item, res.match);
          } else {
            console.error("Failed to fetch match", res.error);
          }
        };
        const promises = partialMatches.map(populateItem);
        await Promise.all(promises);
        if (canceled) {
          // skip changing the state if the effect was
          // canceled by another state change while we
          // were processing the async stuff
          return;
        }
        setRawItems([...fullItems, ...partialMatches]);
      };
      populate();
      return cancelEffect;
    }

    // unify items once they are all populated
    const unify = async () => {
      const unifiedItems = await unifyItems(rawItems, matchRequest);
      if (canceled) {
        // skip changing the state if the effect was
        // canceled by another state change while we
        // were processing the async stuff
        return;
      }
      setRequests(unifiedItems.sort((a, b) => b.updatedAt - a.updatedAt));
      setLoading(false);
    };
    unify();

    return cancelEffect;
  }, [rawItems, matchRequest]);

  useEffect(() => {
    if (!signedIn) {
      return;
    }
    const l = async () => {
      const requestPromise = getRequests();
      const matchPromise = getMatches();
      const [r, m] = await Promise.all([requestPromise, matchPromise]);
      if (r.success && m.success) {
        setRawItems([...r.items, ...m.items]);
      } else {
        if (r.error) {
          console.error("Failed to fetch requests", r.error);
        }
        if (m.error) {
          console.error("Failed to fetch matches", m.error);
        }
        setError({
          message: listError,
          onClick: () => window.location.reload(),
        });
      }
    };
    l();
  }, [signedIn, setError, listError]);

  const updateItem = useCallback(
    ({ item, updateActivity = false, itemId = null }) => {
      if (itemId) {
        const oldData = rawItems.find(({ id }) => id === itemId) || {};
        item = {
          ...oldData,
          ...item,
          populated: false,
        };
      }

      if (updateActivity && item.type === "match") {
        const now = new Date().getTime();
        item.lastActivityTimestamp = now * 1000 * 1000;
        item.date = now;
      }

      setRawItems(h => {
        const newItems = h.filter(({ id }) => id !== item.id);
        return [...newItems, item];
      });
    },
    [rawItems],
  );

  const readMatch = useCallback(
    async item => {
      if (!item || !item.newActivityForClient) {
        return;
      }
      const response = await viewMatch({
        requestId: item.requestId,
        id: item.id,
        source: "client_read",
      });
      if (response.success) {
        item.newActivityForClient = false;
        updateItem({ item });
      } else {
        console.error("Failed to mark match read", response.error);
      }
      // Read Matches
      const unreadMessageIds = (item?.messages || [])
        .filter(i => !i.readAt?.[user.uuid] && i.senderId !== user.uuid)
        .map(i => i.id);
      if (unreadMessageIds.length > 0) {
        const chatResponse = await readMessages({
          itemId: item.id,
          messageIds: unreadMessageIds,
        });
        if (!chatResponse.success) {
          console.error("Failed to mark chats as read", response.error);
        }
      }
    },
    [updateItem, user.uuid],
  );

  const updateStatus = useCallback(
    (item, status) => {
      if (item.type === "match") {
        const now = new Date().getTime();
        item.date = now;
        item.lastStatusChangeTimestamp = Math.floor(now / 1000);
        item.previousStatus = item.status;
      }
      item.status = status;
      updateItem({ item, updateActivity: true });
    },
    [updateItem],
  );

  const addPendingMessage = useCallback(
    (match, tmpId, message) => {
      if (!match.messages) {
        match.messages = [];
      }
      match.messages.push({
        id: tmpId,
        channelId: match.id,
        type: "match",
        createdAt: Math.floor(new Date().getTime() / 1000),
        senderId: user.uuid,
        senderType: "client",
        message,
        metadata: {
          requestId: match.requestId,
        },
      });
      updateItem({ item: match });
    },
    [user.uuid, updateItem],
  );

  const confirmPendingMessage = useCallback(
    (match, tmpId, id) => {
      const pendingIndex = match.messages.findIndex(m => m.id === tmpId);
      if (pendingIndex >= 0) {
        const pendingMessage = match.messages[pendingIndex];
        match.latestMessage = pendingMessage.message;
        match.messages[pendingIndex].id = id;
        updateItem({ item: match, updateActivity: true });
      }
    },
    [updateItem],
  );

  const refusePendingMessage = useCallback(
    (match, tmpId) => {
      match.messages.forEach((m, i) => {
        if (m.id === tmpId) {
          match.messages[i].error = true;
        }
      });
      updateItem({ item: match });
    },
    [updateItem],
  );

  const updateMessages = useCallback(
    (match, messages) => {
      match.messages = filterAndSortMessages(messages);
      updateItem({ item: match });
    },
    [updateItem],
  );

  const addMessage = useCallback(
    async (matchId, message) => {
      const match = rawItems.find(({ id }) => id === matchId);
      if (!match) {
        return;
      }
      const oldMesages = match.messages || [];
      match.messages = filterAndSortMessages([...oldMesages, message]);
      match.latestMessage = message.message;
      updateItem({ item: match });
    },
    [rawItems, updateItem],
  );

  const loadMessages = useCallback(
    async match => {
      if (match.type !== "match") {
        return [];
      }

      // Get latest page of messages
      const response = await getMessages({
        channelId: match.id,
      });
      if (!response.success) {
        console.error("Failed to fetch messages", response.error);
        setError({
          message: listError,
          onClick: () => window.location.reload(),
          onCancel: () => setError(null),
        });
        return [];
      }
      const messageItems = response.items;

      match.hasMoreMessages = messageItems.length === maxMessageResponseLength;
      updateMessages(match, messageItems);
    },
    [updateMessages, setError, listError],
  );

  const loadPrevMessages = useCallback(
    async match => {
      const firstMessageId = match.messages?.[0]?.id;
      if (!firstMessageId || !match.hasMoreMessages) {
        return;
      }
      const response = await getMessages({
        channelId: match.id,
        endMessageId: firstMessageId,
      });
      if (!response.success) {
        console.error("Failed to fetch prev messages", response.error);
        return;
      }
      const remoteMessages = response.items;
      match.hasMoreMessages =
        remoteMessages.length === maxMessageResponseLength;

      if (remoteMessages.length === 0) {
        return;
      }
      const allMessages = [...match.messages, ...remoteMessages];

      updateMessages(match, allMessages);
    },
    [updateMessages],
  );

  const cancelRequest = useCallback(
    async request => {
      const requestId = request.id;
      const res = await _cancelRequest({ requestId });
      if (res.success) {
        const request = rawItems.find(i => i.id === requestId);
        updateStatus(request, Statuses.canceled);

        const acceptedMatches = rawItems.filter(
          i => i.requestId === requestId && i.status === Statuses.accepted,
        );
        acceptedMatches.forEach(m => {
          updateStatus(m, Statuses.canceled);
        });
      } else {
        console.error("Failed to cancel request", res.error);
        setError({
          message: cancelError,
          onClick: () => cancelRequest(request),
          onCancel: () => setError(null),
        });
      }
    },
    [updateStatus, rawItems, setError, cancelError],
  );

  const confirmMatch = useCallback(
    async match => {
      if (match.status === Statuses.accepted) {
        const res = await _confirmMatch({
          requestId: match.requestId,
          id: match.id,
          acceptDatetime: false,
        });
        if (res.success) {
          updateStatus(match, Statuses.confirmed);
        } else {
          console.error("Failed to confirm match", res.error);
          setError({
            message: confirmError,
            onClick: () => confirmMatch(),
            onCancel: () => setError(null),
          });
        }
      }
    },
    [confirmError, setError, updateStatus],
  );

  const resumeRequest = useCallback(
    async request => {
      const res = await resumeSearch({
        requestId: request.id,
      });
      if (res.success) {
        request.matchesCreationMode = "always";
        updateItem({ item: request });
      } else {
        setError({
          message: resumeError,
          onClick: () => resumeRequest(request),
          onCancel: () => setError(null),
        });
      }
    },
    [resumeError, setError, updateItem],
  );
  return (
    <RequestContext.Provider
      value={{
        requests,
        loading,
        readMatch,
        updateItem,
        updateStatus,
        addMessage,
        addPendingMessage,
        confirmPendingMessage,
        refusePendingMessage,
        loadMessages,
        loadPrevMessages,
        resumeRequest,
        cancelRequest,
        confirmMatch,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export default RequestProvider;
