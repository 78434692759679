import { useState, createContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const InboxContext = createContext(null);

const InboxProvider = ({ match, children }) => {
  const [profilePanelActive, setProfilePanelActive] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [requestDetailsPanelActive, setRequestDetailsPanelActive] =
    useState(false);
  const [showContinueSearchingConfirm, setShowContinueSearchingConfirm] =
    useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  // Auto-open profile panel when ?profile=true is set
  useEffect(() => {
    if (searchParams.get("profile") === "true") {
      setProfilePanelActive(true);
      searchParams.delete("profile");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <InboxContext.Provider
      value={{
        profilePanelActive,
        setProfilePanelActive,
        requestDetailsPanelActive,
        setRequestDetailsPanelActive,
        showCancelConfirm,
        setShowCancelConfirm,
        showContinueSearchingConfirm,
        setShowContinueSearchingConfirm,
      }}
    >
      {children}
    </InboxContext.Provider>
  );
};

export default InboxProvider;
