import useCopy from "../../hooks/useCopy";
import styled from "styled-components";
import useAppointmentDetails from "../../hooks/useAppointmentDetails";

import { swapCopyVariables } from "@sussex/react-kit/utils";

const MessageBox = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  padding: 16px;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 16px;
  margin: 15px 0;
`;

const MessageLine = styled.div`
  padding-top: 4px;
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const ClientMessage = ({ match, children }) => {
  const [
    appointmentDetailsWhen,
    appointmentDetailsWhere,
    appointmentDetailsHow,
    appointmentDetailsHowOther,
    appointmentDetailsFor,
  ] = useCopy([
    "client.clientMessage.appointmentDetails.when",
    "client.clientMessage.appointmentDetails.where",
    "client.clientMessage.appointmentDetails.how",
    "client.clientMessage.appointmentDetails.how.other",
    "client.clientMessage.appointmentDetails.for",
  ]);

  const showAppointmentDetailLine =
    match.appointmentDetail &&
    (match.appointmentMethod !== "other" || match.otherVideoDetail);

  const { where, when, how } = useAppointmentDetails(match);

  const isSomeoneElse = match.preferences.relationship[0]?.value === "else";

  return (
    <MessageBox>
      {children}
      {isSomeoneElse && (
        <MessageLine>
          <Label>{appointmentDetailsFor}</Label>
          {match.otherRelationshipName}
        </MessageLine>
      )}
      <MessageLine>
        <Label>{appointmentDetailsWhen}</Label>
        {when}
      </MessageLine>
      {where && (
        <MessageLine>
          <Label>{appointmentDetailsWhere}</Label>
          {where}
        </MessageLine>
      )}
      {how && (
        <MessageLine>
          <Label>{appointmentDetailsHow}</Label>
          {how}
        </MessageLine>
      )}
      {showAppointmentDetailLine && (
        <MessageLine>
          {swapCopyVariables(appointmentDetailsHowOther, {
            APPOINTMENT_DETAIL: match.appointmentDetail,
          })}
        </MessageLine>
      )}
    </MessageBox>
  );
};

export default ClientMessage;
