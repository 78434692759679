import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin-right: 12px;
  overflow: hidden;
  display: flex;

  > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  ${({ theme }) => theme.breakpoints["mobile"]} {
    > img {
      ${({ small }) =>
        small &&
        css`
          width: 40px;
          height: 40px;
        `}
    }
  }
`;

const Photo = ({ item, small }) => {
  return (
    <Wrapper small={small}>
      <img src={item.photo} alt={item.title} />
    </Wrapper>
  );
};

export default Photo;
