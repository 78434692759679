const DropdownArrow = ({ direction }) => {
  let rotation;
  switch (direction) {
    case "up":
      rotation = 180;
      break;
    case "down":
      rotation = 0;
      break;
    default:
      rotation = 0;
  }
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <rect width="40" height="40" rx="20" fill="#F5F7F9" />
      <path
        d="M26 17L20 23L14 17"
        stroke="#2C2D30"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropdownArrow;
