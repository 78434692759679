import { useContext } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { InboxContext } from "../../providers/InboxProvider";
import backArrow from "../../assets/back-arrow.svg";
import { viewProfileClicked } from "../../metrics";
import useCopy from "../../hooks/useCopy";
import Photo from "../Photo";

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  width: 9px;
  height: 15px;

  &:after {
    content: "";
    background-image: url("${backArrow}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: none;
  }
`;

BackButton.defaultProps = {
  type: "button",
};

const PhotoWrapper = styled.div`
  flex: 0 0 auto;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const TherapistTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const OpenProfileLink = styled.div`
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.primaryBlue};
  line-height: 1.5;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-right: auto;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Name = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1.1;
  overflow: hidden;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const ProfileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const MatchHeader = ({ match }) => {
  const navigate = useNavigate();
  const { setProfilePanelActive } = useContext(InboxContext);
  const onBack = () => navigate(`/requests/${match.requestId}`);
  const [viewProfileLinkText] = useCopy([
    "client.matchDetails.viewProfileLink",
  ]);

  const openProfile = () => {
    viewProfileClicked({
      itemId: match.id,
      profileId: match.profileId,
    });
    return setProfilePanelActive(true);
  };

  if (!match) {
    return (
      <Wrapper>
        <BackButton onClick={onBack}></BackButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <BackButton onClick={onBack}></BackButton>
      <ProfileWrapper>
        <PhotoWrapper onClick={openProfile}>
          <Photo item={match} small />
        </PhotoWrapper>
        <ProfileDetailsWrapper>
          <NameWrapper onClick={openProfile}>
            <Name>{match.title}</Name>
          </NameWrapper>
          <TherapistTitle>{match.therapist.title}</TherapistTitle>
        </ProfileDetailsWrapper>
      </ProfileWrapper>
      <OpenProfileLink onClick={openProfile}>
        {viewProfileLinkText}
      </OpenProfileLink>
    </Wrapper>
  );
};

export default MatchHeader;
