import { useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { AttributeContext } from "../../providers/AttributeProvider";
import {
  Preferences,
  AttributeCategories,
  PaymentMethods,
  Relationships,
  Availability,
  AttendancePreferences,
  Ages,
  ChildAges,
  getTherapyForName,
} from "@sussexdir/match-utils";
import { parsePhoneNumber } from "libphonenumber-js";
import { ReactComponent as MaleIcon } from "@sussexdir/match-utils/dist/assets/male.svg";
import { ReactComponent as FemaleIcon } from "@sussexdir/match-utils/dist/assets/female.svg";
import { ReactComponent as NonBinaryIcon } from "@sussexdir/match-utils/dist/assets/non-binary.svg";
import { ReactComponent as ChildIcon } from "@sussexdir/match-utils/dist/assets/child.svg";
import { ReactComponent as CoupleIcon } from "@sussexdir/match-utils/dist/assets/couple.svg";

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  border-radius: 8px;
  margin: 16px;
`;

const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  text-align: left;
  padding: 16px 0;

  &:first-of-type {
    padding: 0 0 16px 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Field = styled.div`
  margin: 16px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

const Footer = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  border-bottom: none;
  text-align: left;
  word-break: break-word;
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const Link = styled.a`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  line-height: 1.5;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 8px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex-direction: row;
  }
`;

const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    text-align: left;
  }
`;

const NameAndDate = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  line-height: 1.3;
`;

const CreatedDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.medGray};
  position: absolute;
  top: 0;
  right: 0;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    position: unset;
    top: unset;
    right: unset;
  }
`;

const Detail = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1.5;
`;

const Avatar = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = ({ relationship, gender }) => {
  return (
    <Avatar>
      {relationship === Relationships.child ? (
        <ChildIcon />
      ) : relationship === Relationships.couple ? (
        <CoupleIcon />
      ) : ["male", "men"].includes(gender) ? (
        <MaleIcon />
      ) : ["female", "women"].includes(gender) ? (
        <FemaleIcon />
      ) : (
        <NonBinaryIcon />
      )}
    </Avatar>
  );
};

const RequestDetails = ({ request }) => {
  const { getAttribute, getAttributeLabel } = useContext(AttributeContext);
  const mounted = useRef(false);
  const [
    availabilityText,
    issuesText,
    issuesComplicatedText,
    paymentText,
    cashText,
    insuranceText,
    flexibleText,
    morningText,
    afternoonText,
    eveningText,
    weekendText,
    onlineText,
    inPersonText,
    inPersonAndOnlineText,
    ageUnder18Text,
    age18To24Text,
    age25To39Text,
    age40To60Text,
    ageOver60Text,
    childAgeToddler,
    childAgeChild,
    childAgePreteen,
    childAgeTeen,
    seekingCoupleText,
    seekingChildText,
    seekingSomeoneElseText,
    languageLabel,
    sexualityLabel,
    therapistGenderLabel,
    religionLabel,
    ethnicityLabel,
    preferencesLabel,
  ] = useCopy([
    "client.matchDetails.availability",
    "client.matchDetails.issues",
    "client.matchDetails.issues.itsComplicated",
    "client.matchDetails.payment",
    "client.matchDetails.cash",
    "client.matchDetails.insurance",
    "client.matchDetails.flexible",
    "client.matchDetails.mornings",
    "client.matchDetails.afternoons",
    "client.matchDetails.evenings",
    "client.matchDetails.weekends",
    "client.matchDetails.online",
    "client.matchDetails.inPerson",
    "client.matchDetails.inPersonAndOnline",
    "client.matchDetails.age.under18",
    "client.matchDetails.age.18To24",
    "client.matchDetails.age.25To39",
    "client.matchDetails.age.40To60",
    "client.matchDetails.age.over60",
    "client.matchDetails.childAge.toddler",
    "client.matchDetails.childAge.child",
    "client.matchDetails.childAge.preteen",
    "client.matchDetails.childAge.teen",
    "client.matchDetails.seekingCouple",
    "client.matchDetails.seekingChild",
    "client.matchDetails.seekingSomeoneElse",
    "client.matchDetails.preferences.language",
    "client.matchDetails.preferences.sexuality",
    "client.matchDetails.preferences.gender",
    "client.matchDetails.preferences.religion",
    "client.matchDetails.preferences.ethnicity",
    "client.matchDetails.preferences.title",
  ]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  if (!request) {
    return null;
  }

  const userCountry = request.location.country;
  const phone = parsePhoneNumber(request.phoneNumber);
  const display =
    phone.country === userCountry
      ? phone.formatNational()
      : phone.formatInternational();

  const createdDate = new Date(request.createdAt * 1000).toLocaleString(
    navigator.language,
    {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  );

  const ageLabels = {
    [Ages.under18]: ageUnder18Text,
    [Ages["18To24"]]: age18To24Text,
    [Ages["25To39"]]: age25To39Text,
    [Ages["40To60"]]: age40To60Text,
    [Ages.over60]: ageOver60Text,
  };

  const childAgeLabels = {
    [ChildAges.toddler]: childAgeToddler,
    [ChildAges.child]: childAgeChild,
    [ChildAges.preteen]: childAgePreteen,
    [ChildAges.teen]: childAgeTeen,
  };

  const paymentLabels = {
    [PaymentMethods.cash]: cashText,
    [PaymentMethods.insurance]: insuranceText,
    [PaymentMethods.flexible]: flexibleText,
  };

  const availabilityLabels = {
    [Availability.mornings]: morningText,
    [Availability.afternoons]: afternoonText,
    [Availability.evenings]: eveningText,
    [Availability.weekends]: weekendText,
  };

  const attendanceLabels = {
    [AttendancePreferences.inPerson]: inPersonText,
    [AttendancePreferences.online]: onlineText,
    [AttendancePreferences.both]: inPersonAndOnlineText,
  };

  const {
    [Preferences.clientGender]: gender,
    [Preferences.age]: age,
    [Preferences.childAge]: childAge,
    [Preferences.relationship]: relationship,
    [Preferences.attendance]: attendance,
    [Preferences.issues]: issues,
    [Preferences.insurance]: insurance,
    [Preferences.issuesWriteIn]: issueWriteIn,
    [Preferences.insuranceWriteIn]: insuranceWriteIn,
    [Preferences.paymentMethod]: paymentMethod,
    [Preferences.availability]: availability,
  } = request.preferences;

  const attendanceKey =
    attendance?.length === 2
      ? AttendancePreferences.both
      : attendance?.[0]?.value;
  const relationshipKey = relationship?.[0]?.value;
  const ageKey = age?.[0]?.value;
  const childAgeKey = childAge?.[0]?.value;
  const genderKey = gender?.[0]?.value;
  const paymentKeys = paymentMethod.map(i => i.value);
  const insuranceKeys = insurance ? insurance.map(i => i.value) : [];
  const issueKeys = issues ? issues.map(i => i.value) : [];
  const allIssues = issueKeys.map(i =>
    getAttributeLabel({ category: AttributeCategories.issues, id: i }),
  );
  if (issueWriteIn?.length) {
    allIssues.push(...issueWriteIn.map(i => i.value));
  }
  if (!allIssues.length) {
    allIssues.push(issuesComplicatedText);
  }
  const allInsurances = insuranceKeys.map(i =>
    getAttributeLabel({ category: AttributeCategories.insurance, id: i }),
  );
  if (insuranceWriteIn) {
    allInsurances.push(insuranceWriteIn?.[0]?.value);
  }

  const genderAttribute = getAttribute({
    category: AttributeCategories.gender,
    id: genderKey,
  });

  const genderLabel = genderAttribute?.label;

  const therapyForName = getTherapyForName(request);

  const location = () => {
    const locationBits = [];
    if (request.location.locality) {
      locationBits.push(request.location.locality);
    }
    if (request.location.areaLevel1) {
      locationBits.push(request.location.areaLevel1);
    }
    return locationBits.join(", ");
  };

  const getDemographic = category => {
    let id = null;
    Object.entries(request.preferences).forEach(([k, v]) => {
      if (category === k) {
        id = v[0]?.value;
      }
    });
    if (!id) {
      return null;
    }
    return getAttributeLabel({ category, id });
  };

  const demographics = {};
  const lookupCategories = [
    AttributeCategories.gender,
    AttributeCategories.communitySexuality,
    AttributeCategories.communityReligion,
    AttributeCategories.communityEthnicity,
    AttributeCategories.language,
  ];
  lookupCategories.forEach(c => {
    const category = getDemographic(c);
    if (category) {
      demographics[c] = category;
    }
  });

  return (
    <Wrapper>
      <Section>
        <HeaderWrapper>
          <Icon relationship={relationshipKey} gender={genderAttribute?.key} />
          <HeaderDetails>
            <NameAndDate>
              <Name>{request.name}</Name>
              <CreatedDate>{createdDate}</CreatedDate>
            </NameAndDate>
            {relationshipKey === Relationships.child && (
              <Detail>{seekingChildText}</Detail>
            )}
            {relationshipKey === Relationships.couple && (
              <Detail>{seekingCoupleText}</Detail>
            )}
            {relationshipKey === Relationships.else && (
              <Detail>
                {swapCopyVariables(seekingSomeoneElseText, {
                  CLIENT_NAME: therapyForName,
                })}
              </Detail>
            )}
            <Detail>
              {location()}
              {" / "}
              {genderLabel},{" "}
              {relationshipKey === Relationships.child
                ? childAgeLabels[childAgeKey]
                : ageLabels[ageKey]}
            </Detail>
            <Detail>
              <Link href={`mailto:${request.email}`}>{request.email}</Link>
              {" / "}
              <Link href={`tel:${request.phoneNumber}`}>{display}</Link>
            </Detail>
          </HeaderDetails>
        </HeaderWrapper>
      </Section>
      {request.anythingElse && (
        <Section>
          <Footer>{request.anythingElse}</Footer>
        </Section>
      )}
      <Section>
        <Field>
          <Label>{availabilityText}</Label>
          <Text>
            {availability.map(i => availabilityLabels[i.value]).join(", ")}
          </Text>
          <Text>{attendanceLabels[attendanceKey]}</Text>
        </Field>
        <Field>
          <Label>{issuesText}</Label>
          <Text>{allIssues.filter(a => !!a).join(", ")}</Text>
        </Field>
        <Field>
          <Label>{paymentText}</Label>
          <Text>
            {paymentKeys
              .map(l => paymentLabels[l])
              .filter(a => !!a)
              .join(", ")}
            {allInsurances.length > 0 &&
              ` - ${allInsurances.filter(a => !!a).join(", ")}`}
          </Text>
        </Field>
      </Section>
      {Object.keys(demographics).length > 0 && (
        <Section>
          <Label>{preferencesLabel}</Label>
          {demographics[AttributeCategories.gender] && (
            <Text>
              {therapistGenderLabel}:{" "}
              {getDemographic(AttributeCategories.gender)}
            </Text>
          )}
          {demographics[AttributeCategories.communitySexuality] && (
            <Text>
              {sexualityLabel}:{" "}
              {getDemographic(AttributeCategories.communitySexuality)}
            </Text>
          )}
          {demographics[AttributeCategories.communityReligion] && (
            <Text>
              {religionLabel}:{" "}
              {getDemographic(AttributeCategories.communityReligion)}
            </Text>
          )}
          {demographics[AttributeCategories.communityEthnicity] && (
            <Text>
              {ethnicityLabel}:{" "}
              {getDemographic(AttributeCategories.communityEthnicity)}
            </Text>
          )}
          {demographics[AttributeCategories.language] && (
            <Text>
              {languageLabel}: {getDemographic(AttributeCategories.language)}
            </Text>
          )}
        </Section>
      )}
    </Wrapper>
  );
};

export default RequestDetails;
