import { useState, useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../hooks/useCopy";
import {
  tokenConfirmMatch,
  tokenGetMatch,
  getTherapist,
  userTypes,
} from "../httpapi";
import { getPhotoUrl } from "../utils";
import PanelHeader from "../components/PanelHeader";
import AppointmentCard from "../components/AppointmentCard";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";
import AlreadyClicked from "./shared/AlreadyClicked";
import LoginLink from "./shared/LoginLink";
import { useNavigate } from "react-router-dom";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const Subtitle = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const Inner = styled.div`
  max-width: 500px;
  margin: 30px auto 0;
`;

const LoginWrapper = styled.div`
  margin: 30px 0;
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
  width: 100%;
  margin: 0 auto;
`;

export default function ConfirmAppointmentView() {
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [
    title,
    loginText,
    loginErrorText,
    emailText,
    errorTitle,
    genericError,
  ] = useCopy([
    "client.confirmLanding.title",
    "client.confirmLanding.loginText",
    "client.confirmLanding.loginText.error",
    "client.confirmLanding.emailText",
    "client.confirmLanding.errorTitle",
    "client.confirmLanding.genericError",
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const schedule = params.get("schedule");
    const acceptDatetime = schedule === "true";

    if (!token) {
      setError("generic");
      setLoading(false);
      return;
    }

    const load = async () => {
      const matchRes = await tokenGetMatch({
        token,
        userType: userTypes.CLIENT,
      });
      if (!matchRes.success) {
        setError("generic");
        setLoading(false);
        return;
      }
      const therapistRes = await getTherapist(matchRes.profileId);
      if (!therapistRes.success) {
        setError("generic");
        setLoading(false);
        return;
      }

      matchRes.therapist = therapistRes;
      matchRes.photo = getPhotoUrl(matchRes.profileId);
      setMatch(matchRes);

      const res = await tokenConfirmMatch({ token, acceptDatetime });
      if (!res.success) {
        setError(res.conflict ? "conflict" : "generic");
        setLoading(false);
        return;
      }

      if (!acceptDatetime) {
        navigate("/");
        return;
      }

      setLoading(false);
    };

    load();
  }, [navigate]);

  if (loading) {
    return (
      <PageWrapper>
        <PanelWrapper>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  if (error) {
    if (error === "conflict") {
      return <AlreadyClicked match={match} />;
    }

    return (
      <PageWrapper>
        <PanelWrapper>
          <Panel>
            <PanelHeader title={errorTitle} />
            <Inner>
              <ErrorBox>{genericError}</ErrorBox>
              <LoginWrapper>
                {swapCopyVariables(loginErrorText, { LINK: <LoginLink /> })}
              </LoginWrapper>
            </Inner>
          </Panel>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <Panel>
          <PanelHeader title={title} />
          <Inner>
            <Subtitle>{emailText}</Subtitle>
            <AppointmentCard match={match} showTherapist />
            <LoginWrapper>
              {swapCopyVariables(loginText, { LINK: <LoginLink /> })}
            </LoginWrapper>
          </Inner>
        </Panel>
      </PanelWrapper>
    </PageWrapper>
  );
}
