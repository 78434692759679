import { useState, useContext } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { H2 } from "@sussex/react-kit/elements";

import { InboxContext } from "../../providers/InboxProvider/InboxProvider";
import useCopy from "../../hooks/useCopy";
import { swapCopyVariables } from "@sussex/react-kit/utils";

import DropdownArrow from "../../assets/DropdownArrow";

import MatchesList from "./MatchesList";
import RequestsDropdown from "./RequestsDropdown";
import { Statuses } from "@sussexdir/match-utils";

const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
const HeaderText = styled(H2)`
  margin: 0;
  font-size: 26px;
  line-height: 1.3;
`;
const ClientName = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
`;
const IntroText = styled.div`
  margin-bottom: 16px;
`;
const DropdownButton = styled.button`
  display: block;
  background: none;
  appearance: none;
  padding: 0;
  border: none;
  cursor: pointer;
`;
DropdownButton.defaultProps = {
  type: "button",
};

const Messages = ({ activeRequest, requests }) => {
  const navigate = useNavigate();
  const { matchId } = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { setRequestDetailsPanelActive } = useContext(InboxContext);

  const activeRequests = requests.filter(r => r.active);
  const closedRequests = requests.filter(r => !r.active);

  const matches = activeRequest.matches || [];
  const activeMatches = matches.filter(m => m.active);
  const closedMatches = matches.filter(m => !m.active);

  const [inboxTitle, forText, introText] = useCopy([
    "client.inbox.title",
    "client.forName",
    "client.inbox.messagesIntro",
  ]);
  const deadStatuses = [Statuses.timedout, Statuses.closed];
  const showResponding =
    activeRequest.matches.length > 0 &&
    !deadStatuses.includes(activeRequest.status);

  return (
    <Wrapper>
      <Header>
        <div>
          <HeaderText>{inboxTitle}</HeaderText>
          <ClientName
            onClick={() => {
              setRequestDetailsPanelActive(true);
            }}
          >
            {swapCopyVariables(forText, { NAME: activeRequest.name })}
          </ClientName>
        </div>
        {requests.length > 1 && (
          <DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownArrow direction={dropdownOpen ? "up" : "down"} />
          </DropdownButton>
        )}
      </Header>
      {dropdownOpen && (
        <RequestsDropdown
          requests={activeRequests}
          closedRequests={closedRequests}
          activeRequest={activeRequest.id}
          setActive={newId => {
            setDropdownOpen(false);
            setTimeout(() => {
              navigate(`/requests/${newId}`);
            });
          }}
        />
      )}
      {showResponding ? <IntroText>{introText}</IntroText> : null}
      <MatchesList
        request={activeRequest}
        matches={activeMatches}
        closedMatches={closedMatches}
        activeMatch={matchId}
      />
    </Wrapper>
  );
};

export default Messages;
