import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { Loading } from "@sussex/react-kit/assets";

import { RequestContext } from "../../providers/RequestProvider";

import ChatLog from "../Chat/ChatLog";
import DetailWrapper from "./DetailWrapper";
import StatusMessage from "./StatusMessage";
import { isChatEnabled } from "../../utils";
import { isDeclinedMatch } from "@sussexdir/match-utils";

const NoneHeaderWrapper = styled.div`
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: none;
  }
`;

const NoneWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const MatchDetail = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const matchId = useRef(null);
  const { loadMessages } = useContext(RequestContext);
  const scrollRef = useRef(null);

  const showChatLog = isChatEnabled(match) || isDeclinedMatch(match);

  useEffect(() => {
    if (!match || match?.id === matchId.current) {
      return;
    }
    setLoading(true);
    matchId.current = match.id;
    const load = async () => {
      await loadMessages(match);
      setLoading(false);
    };
    load();
  }, [match, loadMessages]);

  if (!match) {
    return (
      <DetailWrapper>
        <NoneHeaderWrapper></NoneHeaderWrapper>
        <NoneWrapper>None Selected</NoneWrapper>
      </DetailWrapper>
    );
  }

  if (loading) {
    return (
      <DetailWrapper match={match}>
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </DetailWrapper>
    );
  }

  if (showChatLog) {
    return (
      <DetailWrapper match={match} ref={scrollRef}>
        <ChatLog match={match} scrollRef={scrollRef}>
          {!match.hasMoreMessages && isChatEnabled(match) ? (
            <StatusMessage match={match} />
          ) : null}
        </ChatLog>
        {!isChatEnabled(match) ? <StatusMessage match={match} /> : null}
      </DetailWrapper>
    );
  }

  return (
    <DetailWrapper match={match}>
      <StatusMessage match={match} />
    </DetailWrapper>
  );
};

export default MatchDetail;
