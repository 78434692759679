const PersonFace = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 7C9.15685 7 10.5 5.65685 10.5 4C10.5 2.34315 9.15685 1 7.5 1C5.84315 1 4.5 2.34315 4.5 4C4.5 5.65685 5.84315 7 7.5 7Z"
        stroke="#2C2D30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.5C5.77609 9.5 4.12279 10.1848 2.90381 11.4038C1.68482 12.6228 1 14.2761 1 16H14C14 14.2761 13.3152 12.6228 12.0962 11.4038C10.8772 10.1848 9.22391 9.5 7.5 9.5Z"
        stroke="#2C2D30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonFace;
