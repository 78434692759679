import { useContext } from "react";
import { CopyContext } from "../providers/CopyProvider";

export default function useCopy(keys) {
  const { copy } = useContext(CopyContext);
  if (!copy) {
    throw new Error("useCopy must be used within a CopyProvider");
  }
  return keys.map(key => copy[key] || "");
}
