import styled from "styled-components";

const PanelWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  background-color: white;

  ${({ theme }) => theme.breakpoints[">tablet"]} {
    flex: 1 0 auto;
    margin: 0 auto;
    width: calc(60%);
    min-width: 800px;
    max-width: 900px;
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    min-height: min(600px, 80vh);
  }
`;

export default PanelWrapper;
