import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Button } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import calendarLinks from "./calendarlinks";
import Photo from "../Photo";
import MethodIntro from "./MethodIntro";
import ClientMessage from "../ClientMessage";
import useAppointmentDetails from "../../hooks/useAppointmentDetails";

const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const TherapistInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Information = styled.div``;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Title = styled.div``;

const AppointmentDetails = styled.div`
  word-break: break-word;
`;

const CalendarLinks = styled.div`
  display: grid;
  gap: 10px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CalendarButton = styled(Button)`
  min-width: auto;
`;

const AppointmentCard = ({ match, showTherapist }) => {
  const { how } = useAppointmentDetails(match);
  const [calendarSummary, detailText] = useCopy([
    "client.confirmLanding.appointmentDetails.summary",
    "client.confirmLanding.appointmentDetails.details",
  ]);

  const howValue = Array.isArray(how) ? how.join("") : how

  const calendarDetails = howValue
    ? `${detailText}\n\n${howValue}`
    : detailText;

  const cals = calendarLinks({
    startTime: match.proposedDatetime,
    endTime: match.proposedEndDatetime,
    method: match.appointmentMethod,
    locationUrl:
      match.appointmentMethod === "sessions" ? match.sessionsUrl : "",
    summary: encodeURIComponent(calendarSummary),
    details: encodeURIComponent(
      swapCopyVariables(calendarDetails, {
        NAME: match?.therapist?.fullName,
      }).join(""),
    ),
  });

  return (
    <>
      <Wrapper>
        <AppointmentDetails>
          <ClientMessage match={match}>
            {showTherapist && match.therapist && (
              <TherapistInfo>
                <Photo item={match} />
                <Information>
                  <Name>{match.therapist.fullName}</Name>
                  {match.therapist.title && (
                    <Title>{match.therapist.title}</Title>
                  )}
                </Information>
              </TherapistInfo>
            )}
          </ClientMessage>
        </AppointmentDetails>
      </Wrapper>
      <MethodIntro match={match} />
      <CalendarLinks>
        {Object.keys(cals).map(name => (
          <CalendarButton
            variant="white"
            key={name}
            onClick={() => window.open(cals[name], "_blank")}
          >
            {name}
          </CalendarButton>
        ))}
      </CalendarLinks>
    </>
  );
};

export default AppointmentCard;
