import { CognitoUserPool } from "amazon-cognito-identity-js";
import MemoryStorage from "./memory-storage";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL,
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
  Storage: MemoryStorage,
  endpoint: process.env.REACT_APP_AUTH_API_URL + "/clients/",
  fetchOptions: {
    credentials: "include",
  },
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
