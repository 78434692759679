import { useState, createContext } from "react";

export const ErrorContext = createContext(null);

const ErrorProvider = ({ children }) => {
  /**
   * @typedef error
   * @property {string} message
   * @property {function} onClick
   * @property {string} [title]
   * @property {string} [buttonText]
   * @property {function} [onCancel]
   * @property {function} [cancelButtonText]
   */
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
