const ChildFace = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_905_27388)">
        <path
          d="M5 10.5C5.55228 10.5 6 10.0523 6 9.5C6 8.94772 5.55228 8.5 5 8.5C4.44772 8.5 4 8.94772 4 9.5C4 10.0523 4.44772 10.5 5 10.5Z"
          fill="#2C2D30"
        />
        <path
          d="M11 10.5C11.5523 10.5 12 10.0523 12 9.5C12 8.94772 11.5523 8.5 11 8.5C10.4477 8.5 10 8.94772 10 9.5C10 10.0523 10.4477 10.5 11 10.5Z"
          fill="#2C2D30"
        />
        <path
          d="M0.535156 7.77218C2.70816 4.87218 6.55116 8.18718 10.7272 4.40918C12.7722 7.64818 15.4692 7.81518 15.4692 7.81518"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_27388">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChildFace;
