import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import App, { Authenticated } from "./App";
import LandingView from "./pages/LandingView";
import NotFoundView from "./pages/NotFoundView";
import Confirm from "./pages/ConfirmAppointmentView";
import Decline from "./pages/DeclineAppointmentView";
import TherapistDecline from "./pages/TherapistDeclineAppointmentView";
import DeclinedMatch from "./pages/survey/DeclinedMatch";
import MatchFound from "./pages/survey/MatchFound";
import ExpandSearch from "./pages/survey/ExpandSearch";
import Introduction from "./pages/survey/Introduction";
import AlreadyConnected from "./pages/AlreadyConnectedView";
import PageMetricProvider from "./providers/PageMetricProvider";

const authenticated = children => <Authenticated>{children}</Authenticated>;

function AppRouter() {
  return (
    <Router>
      <PageMetricProvider />
      <App>
        <Routes>
          <Route
            path="/"
            element={authenticated(<Navigate replace to="/requests" />)}
          />
          <Route path="requests" element={authenticated(<LandingView />)}>
            <Route path=":requestId" element={authenticated(<LandingView />)}>
              <Route path=":matchId" element={authenticated(<LandingView />)} />
            </Route>
          </Route>
          <Route path="confirm" element={<Confirm />} />
          <Route path="decline" element={<Decline />} />
          <Route path="already-connected" element={<AlreadyConnected />} />
          <Route path="/feedback/match-found" element={<MatchFound />} />
          <Route path="/feedback/decline-match" element={<DeclinedMatch />} />
          <Route path="/feedback/expand-search" element={<ExpandSearch />} />
          <Route path="/feedback/introduction" element={<Introduction />} />
          <Route path="/therapist/decline" element={<TherapistDecline />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </App>
    </Router>
  );
}

export default AppRouter;
