import { useState, useEffect, useContext, createContext, useRef } from "react";
import { UserContext } from "../UserProvider";
import { AttributeContext } from "../AttributeProvider";
import { ValidAttributeCategories } from "@sussexdir/match-utils";
import { getLocale } from "../../utils";

export const AppStateContext = createContext(null);

const AppStateProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const started = useRef(false);
  const { fetchCurrentUser } = useContext(UserContext);
  const { fetchAttributes } = useContext(AttributeContext);

  useEffect(() => {
    if (started.current) {
      return;
    }
    started.current = true;
    Promise.all([
      fetchCurrentUser(),
      fetchAttributes({
        categories: ValidAttributeCategories,
        locale: getLocale(),
      }),
    ])
      .catch(err => {
        console.error("Failed to fetch app init data", err);
        setError(true);
      })
      .then(() => {
        setLoading(false);
      });
  }, [fetchCurrentUser, fetchAttributes]);

  return (
    <AppStateContext.Provider
      value={{
        loading,
        error,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
