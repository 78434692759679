import { useContext } from "react";
import { ChatLog as Log } from "@sussex/react-kit/message-center";
import { RequestContext } from "../../providers/RequestProvider";
import { UserContext } from "../../providers/UserProvider";
import useCopy from "../../hooks/useCopy";

const ChatLog = ({ match, scrollRef, children }) => {
  const { loadPrevMessages } = useContext(RequestContext);
  const { user } = useContext(UserContext);
  const [errorText, readText] = useCopy([
    "client.matchChat.sendMessageError",
    "client.matchChat.readReceipt",
  ]);

  const messages = (match.messages || []).map(m => ({
    createdAt: m.createdAt * 1000,
    id: m.id,
    self: m.senderId === user.uuid,
    message: m.message,
    error: m.error,
  }));

  const getNextPage = async () => {
    await loadPrevMessages(match);
  };

  const readMessages =
    match.lastTherapistReadAt > 0
      ? messages
          .filter(
            e => e.createdAt <= match.lastTherapistReadAt * 1000 && e.self,
          )
          .sort((a, b) => b.createdAt - a.createdAt)
      : [];

  const lastReadMessageID = readMessages?.[0]?.id || null;

  const lastReadDate = match.lastTherapistReadAt
    ? new Date(match.lastTherapistReadAt * 1000)
    : null;

  return (
    <Log
      messages={messages}
      hasMore={match.hasMoreMessages}
      locale={navigator.language}
      getNextPage={getNextPage}
      errorText={errorText}
      readText={readText}
      lastReadDate={lastReadDate}
      lastReadMessageID={lastReadMessageID}
      scrollRef={scrollRef}
    >
      {children}
    </Log>
  );
};

export default ChatLog;
