import { uuid, truncateString } from "./utils";

const baseDirectoryURL = `${process.env.REACT_APP_BASE_DIRECTORY_API_URL}`;
const baseAuthURL = `${process.env.REACT_APP_AUTH_API_URL}`;
const baseGatewayURL = `${process.env.REACT_APP_GATEWAY_API_URL}/`;

const actions = {
  MATCH: {
    LIST_MATCHES: "match/matches/list",
    LIST_REQUESTS: "match/requests/list",
    GET_MATCH: "match/matches",
    VIEW_MATCH: "match/matches/view",
    CONFIRM_MATCH: "match/matches/confirm",
    ACCEPT_MATCH: "match/matches/accept",
    DECLINE_MATCH: "match/matches/decline",
    CANCEL_REQUEST: "match/requests/cancel",
    REFUSE_MATCH: "match/matches/refuse",
    EXPAND_SEARCH: "match/requests/expand",
    RESUME_SEARCH: "match/requests/resume",
    RESOLVE_MATCH: "match/matches/resolve",
    PROFILE: "match/profiles",
  },
  CHAT: {
    LIST: "chat/messages",
    SEND: "chat/messages",
    READ: "chat/read",
  },
  METRICS: "metrics",
};

const userTypes = {
  THERAPIST: 1,
  CLIENT: 2,
};

const applicationId = "ClientMessageCenter";
const sessionId = uuid();

const getAttributes = async ({
  categories,
  locale,
  regionCode,
  context = "therapists",
}) => {
  let attributes = null;
  const requestPayload = {
    categories,
    locale,
    regionCode,
    context,
  };
  try {
    const response = await fetch(`${baseDirectoryURL}/attribute-categories`, {
      method: "POST",
      body: JSON.stringify(requestPayload),
    });
    if (response.ok) {
      const json = await response.json();
      attributes = json.categories;
    }
  } catch (e) {
    console.log(e);
  }
  return attributes;
};

const getCurrentUser = async () => {
  let user = null;
  try {
    const response = await fetch(`${baseAuthURL}/clients/get-user`, {
      method: "GET",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    if (response.ok) {
      const json = await response.json();
      if (json.uuid) {
        user = {
          uuid: json.uuid,
          name: json.name,
          email: json.email,
          emailVerified: json.emailVerified,
          phoneNumber: json.phoneNumber,
          phoneNumberVerified: json.phoneNumberVerified,
          locale: json.locale,
          idToken: json.idToken,
          refreshToken: json.refreshToken,
        };
      }
    }
  } catch (e) {
    console.log(e);
  }
  return user;
};

const getUserStatus = async username => {
  let status = "";
  try {
    const response = await fetch(
      `${baseAuthURL}/clients/get-status?username=${encodeURIComponent(
        username,
      )}`,
      {
        method: "GET",
      },
    );
    if (response.ok) {
      const json = await response.json();
      status = json.status;
    }
  } catch (e) {
    console.log(e);
  }
  return status;
};

const getMatch = async (requestId, id) => {
  const res = {
    success: true,
    match: null,
  };
  try {
    const url =
      baseGatewayURL +
      actions.MATCH.GET_MATCH +
      `?requestId=${requestId}&id=${id}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res.match = json;
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const getMatches = async () => {
  const res = {
    success: true,
    items: [],
  };
  try {
    const url =
      baseGatewayURL + actions.MATCH.LIST_MATCHES + "?disablePagination=true";
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res.items = json.items;
  } catch (e) {
    console.log({ e });
    res.success = false;
    res.error = e;
  }
  return res;
};

const getRequests = async () => {
  const res = {
    success: true,
    items: [],
  };
  try {
    const url = baseGatewayURL + actions.MATCH.LIST_REQUESTS;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res.items = json.items;
  } catch (e) {
    console.log({ e });
    res.success = false;
    res.error = e;
  }
  return res;
};

const getTherapist = async profileId => {
  let res = {
    success: false,
  };
  try {
    let url = baseGatewayURL + actions.MATCH.PROFILE + `?id=${profileId}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res = json;
    res.fullName = truncateString(res.fullName);
    res.success = true;
  } catch (e) {
    res.error = e;
  }
  return res;
};

const getMessages = async ({
  channelId,
  startMessageId = null,
  endMessageId = null,
}) => {
  const res = {
    success: true,
    items: [],
  };
  try {
    let url = baseGatewayURL + actions.CHAT.LIST + `?channelId=${channelId}`;
    if (startMessageId) {
      url += `&startMessageId=${startMessageId}`;
    }
    if (endMessageId) {
      url += `&endMessageId=${endMessageId}`;
    }
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res.items = json.items;
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const sendMessage = async ({ itemId, message }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.CHAT.SEND;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channelId: itemId,
        message,
      }),
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.errorMessage);
    }
    res.id = json.id;
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const readMessages = async ({ itemId, messageIds }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.CHAT.READ;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channelId: itemId,
        messageIds,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const viewMatch = async ({ requestId, id, source }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.VIEW_MATCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
        id,
        source,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const logoutUser = async () => {
  let res = {
    success: true,
  };
  try {
    const response = await fetch(`${baseAuthURL}/clients/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
      },
    });
    if (!response.ok) {
      const json = await response.json();
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
  }
  return res;
};

const cancelRequest = async ({ requestId }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.CANCEL_REQUEST;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const refuseMatch = async ({ requestId, id }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.REFUSE_MATCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
        id,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const confirmMatch = async ({ requestId, id, acceptDatetime }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.CONFIRM_MATCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
        id,
        acceptDatetime,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const resolveMatch = async ({ requestId, matchId, type, continueMatching }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.RESOLVE_MATCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.CLIENT,
      },
      body: JSON.stringify({
        requestId,
        id: matchId,
        type,
        continueMatching,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const resumeSearch = async ({ requestId }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.RESUME_SEARCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const tokenExpandSearch = async ({ token, expandSearch }) => {
  let res = {
    success: true,
  };

  const url = baseGatewayURL + actions.MATCH.EXPAND_SEARCH;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.CLIENT,
        Authorization: token,
      },
      body: JSON.stringify({
        expandSearch,
      }),
    });

    if (!response.ok) {
      const json = await response.json();

      throw new Error(json.errorMessage);
    }
    res.success = true;
  } catch (e) {
    res.success = false;
  }

  return res;
};

const tokenViewMatch = async ({ token, requestId, id, source }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.VIEW_MATCH;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: token,
        "User-Type": userTypes.CLIENT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestId,
        id,
        source,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const tokenGetMatch = async ({ token, userType }) => {
  let res = {
    success: true,
  };

  const url = baseGatewayURL + actions.MATCH.GET_MATCH;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: token,
        "User-Type": userType,
      },
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
    res = await response.json();
    res.success = true;
  } catch (e) {
    res.success = false;
  }

  return res;
};

const tokenDeclineMatch = async ({ token }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.DECLINE_MATCH;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.THERAPIST,
        Authorization: token,
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const tokenRefuseMatch = async ({ token }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.REFUSE_MATCH;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.CLIENT,
        Authorization: token,
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      const json = await response.json();
      if (response.status === 409) {
        res.conflict = true;
      }
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const tokenConfirmMatch = async ({ token, acceptDatetime }) => {
  let res = {
    success: true,
  };

  const url = baseGatewayURL + actions.MATCH.CONFIRM_MATCH;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.CLIENT,
        Authorization: token,
      },
      body: JSON.stringify({
        acceptDatetime,
      }),
    });

    if (!response.ok) {
      const json = await response.json();
      if (response.status === 409) {
        res.conflict = true;
      }
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
  }

  return res;
};

const tokenResolveMatch = async ({ token, type, continueMatching }) => {
  const res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.MATCH.RESOLVE_MATCH;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Type": userTypes.CLIENT,
        Authorization: token,
      },
      body: JSON.stringify({
        type,
        continueMatching,
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      if (response.status === 409) {
        res.conflict = true;
      }
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const sendMetric = async ({ name, value, metadata }) => {
  let res = {
    success: true,
  };
  try {
    const url = baseGatewayURL + actions.METRICS;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicationId,
        sessionId,
        metrics: [
          {
            name,
            value,
            metadata,
            timestamp: Math.floor(Date.now() / 1000),
          },
        ],
      }),
    });
    if (!response.ok) {
      const json = await response.json();
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
  }
  return res;
};

export {
  userTypes,
  getCurrentUser,
  getMatch,
  getMatches,
  getRequests,
  getTherapist,
  viewMatch,
  getMessages,
  sendMessage,
  readMessages,
  getAttributes,
  getUserStatus,
  logoutUser,
  cancelRequest,
  refuseMatch,
  resolveMatch,
  confirmMatch,
  resumeSearch,
  tokenGetMatch,
  tokenDeclineMatch,
  tokenRefuseMatch,
  tokenConfirmMatch,
  tokenExpandSearch,
  tokenViewMatch,
  tokenResolveMatch,
  sendMetric,
};
