import styled from "styled-components";
import BaristaGirl from "../../assets/therapist-illustration.png";
import { H1 } from "@sussex/react-kit/elements";

const Illustration = styled.img`
  pointer-events: none;
  width: 40px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 72px;
  }
`;

const Content = styled.div`
  margin-left: 16px;
  max-width: 330px;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding: 32px 24px;
  }
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = ({ title }) => {
  return (
    <Wrapper>
      <MainContent>
        <Illustration src={BaristaGirl} alt={title} />
        <Content>
          <H1>{title}</H1>
        </Content>
      </MainContent>
    </Wrapper>
  );
};
export default Header;
