import { useContext } from "react";
import { Button } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { InboxContext } from "../../providers/InboxProvider";
import { RequestContext } from "../../providers/RequestProvider";
import useCopy from "../../hooks/useCopy";
import Slideout from "../Slideout";
import RequestDetails from "../RequestDetails";
import Profile from "../Profile";
import ConfirmationPrompt from "../ConfirmationPrompt";

const Slideouts = ({ request = {}, match = {} }) => {
  const {
    requestDetailsPanelActive,
    setRequestDetailsPanelActive,
    profilePanelActive,
    setProfilePanelActive,
    showCancelConfirm,
    setShowCancelConfirm,
    showContinueSearchingConfirm,
    setShowContinueSearchingConfirm,
  } = useContext(InboxContext);
  const { cancelRequest, resumeRequest } = useContext(RequestContext);
  const [
    detailsTitle,
    cancelText,
    detailsSubtitle,
    cancelConfirmPrompt,
    continueSearchingConfirmPrompt,
  ] = useCopy([
    "client.matchDetails.detailsTitle",
    "client.matchDetails.cancelRequest",
    "client.matchDetails.detailsSubtitle",
    "client.cancelConfirm.prompt",
    "client.continueSearchingConfirm.prompt",
  ]);

  const handleCancel = async () => {
    await cancelRequest(request);
    setShowCancelConfirm(false);
  };

  const handleContinue = async () => {
    await resumeRequest(request);
    setShowContinueSearchingConfirm(false);
  };

  if (!request) {
    return null;
  }

  return (
    <>
      <Slideout
        active={requestDetailsPanelActive}
        title={detailsTitle}
        subtitle={swapCopyVariables(detailsSubtitle, {
          NAME: request.name,
        })}
        footer={
          request.active && (
            <Button
              variant="white"
              full
              onClick={() => {
                setRequestDetailsPanelActive(false);
                setShowCancelConfirm(true);
              }}
            >
              {cancelText}
            </Button>
          )
        }
        onClose={() => setRequestDetailsPanelActive(false)}
      >
        <RequestDetails request={request} />
      </Slideout>
      <Slideout
        active={profilePanelActive}
        onClose={() => setProfilePanelActive(false)}
      >
        <Profile match={match} key={match.profileId} />
      </Slideout>
      {showCancelConfirm && (
        <ConfirmationPrompt
          prompt={cancelConfirmPrompt}
          onConfirm={handleCancel}
          onCancel={() => setShowCancelConfirm(false)}
        />
      )}
      {showContinueSearchingConfirm && (
        <ConfirmationPrompt
          prompt={continueSearchingConfirmPrompt}
          onConfirm={handleContinue}
          onCancel={() => setShowContinueSearchingConfirm(false)}
        />
      )}
    </>
  );
};

export default Slideouts;
