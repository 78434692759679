const CoupleFaces = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_905_27374)">
        <path
          d="M9.775 11.7934L7.312 11.0934C7.2191 11.067 7.13585 11.0142 7.07232 10.9414C7.00879 10.8687 6.96769 10.7791 6.954 10.6834L6.747 9.22643C7.26955 8.98762 7.71254 8.60377 8.0233 8.12053C8.33405 7.63729 8.49951 7.07497 8.5 6.50043V5.12643C8.51213 4.33088 8.21632 3.56142 7.67439 2.97887C7.13246 2.39631 6.38636 2.04575 5.592 2.00043C5.19036 1.98811 4.79034 2.0566 4.41568 2.20184C4.04101 2.34708 3.69935 2.5661 3.41095 2.84591C3.12256 3.12573 2.89332 3.46062 2.73683 3.83073C2.58035 4.20084 2.49981 4.59861 2.5 5.00043V6.50043C2.50049 7.07497 2.66595 7.63729 2.9767 8.12053C3.28746 8.60377 3.73045 8.98762 4.253 9.22643L4.046 10.6794C4.03231 10.7751 3.99121 10.8647 3.92768 10.9374C3.86415 11.0102 3.7809 11.063 3.688 11.0894L1.225 11.7894C1.01619 11.8492 0.832499 11.9752 0.701698 12.1486C0.570897 12.322 0.500096 12.5333 0.5 12.7504V15.0004H10.5V12.7544C10.4999 12.5373 10.4291 12.326 10.2983 12.1526C10.1675 11.9792 9.98381 11.8532 9.775 11.7934Z"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 15.0005H15.5V11.7815C15.5 11.5585 15.4255 11.342 15.2883 11.1663C15.1512 10.9905 14.9592 10.8656 14.743 10.8115L11.826 10.0825C11.7294 10.0584 11.6423 10.006 11.5756 9.93207C11.509 9.85814 11.466 9.766 11.452 9.66746L11.247 8.22646C11.7696 7.98764 12.2125 7.6038 12.5233 7.12056C12.8341 6.63731 12.9995 6.075 13 5.50046V4.12646C13.0121 3.33091 12.7163 2.56145 12.1744 1.97889C11.6325 1.39634 10.8864 1.04577 10.092 1.00046C9.53447 0.983198 8.98316 1.12172 8.5 1.40046"
          stroke="#2C2D30"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_27374">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoupleFaces;
