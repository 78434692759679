import styled from "styled-components";

import radioChecked from "../../assets/radio-checked.svg";
import radioUnchecked from "../../assets/radio-unchecked.svg";

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 250px;
  align-items: center;
  margin-top: 16px;
`;

const RadioLabel = styled.div`
  flex: 1;
  margin-left: 9px;
`;

const RadioBox = styled.div`
  background: url(${({ checked }) => (checked ? radioChecked : radioUnchecked)})
    center no-repeat;
  width: 30px;
  height: 30px;
`;

const RadioOption = ({ onClick, checked, label }) => {
  return (
    <Wrapper onClick={onClick}>
      <RadioBox checked={checked} />
      <RadioLabel>{label}</RadioLabel>
    </Wrapper>
  );
};

export default RadioOption;
