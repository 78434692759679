import { useEffect, useState } from "react";
import styled from "styled-components";
import { InfoBox, ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import SurveyTemplate from "./shared/SurveyTemplate";
import HomeLink from "./shared/HomeLink";
import useCopy from "../../hooks/useCopy";
import { tokenExpandSearch } from "../../httpapi";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ExpandSearch = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const expandSearch = params.get("expand-search") === "yes";

  const [
    matchTitle,
    expandTitle,
    noExpandTitle,
    expandBody,
    noExpandBody,
    duplicateText,
    genericError,
    errorTitle,
  ] = useCopy([
    "client.matchRequest",
    "client.feedback.expandSearch.title",
    "client.feedback.noExpandSearch.title",
    "client.feedback.expandSearch.body",
    "client.feedback.noExpandSearch.body",
    "client.confirmLanding.duplicateConfirmation",
    "client.confirmLanding.genericError",
    "client.confirmLanding.errorTitle",
  ]);

  useEffect(() => {
    if (!token) {
      setError("generic");
      setLoading(false);
      return;
    }

    const load = async () => {
      const params = {
        token,
        expandSearch,
      };
      const res = await tokenExpandSearch(params);
      if (!res.success) {
        setError(res.conflict ? "conflict" : "generic");
      }
      setLoading(false);
    };

    load();
  }, [expandSearch, token]);

  if (loading) {
    return (
      <SurveyTemplate title={matchTitle}>
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </SurveyTemplate>
    );
  }

  if (error) {
    const errorText = {
      generic: genericError,
      conflict: duplicateText,
    };

    return (
      <SurveyTemplate title={errorTitle}>
        <ErrorBox>{errorText[error]}</ErrorBox>
        <HomeLink />
      </SurveyTemplate>
    );
  }

  const title = expandSearch ? expandTitle : noExpandTitle;
  const message = expandSearch ? expandBody : noExpandBody;

  return (
    <SurveyTemplate title={title}>
      <InfoBox>{message}</InfoBox>
      <HomeLink />
    </SurveyTemplate>
  );
};

export default ExpandSearch;
