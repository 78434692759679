import { useCallback, useContext } from "react";
import WebSocket from "./Websocket";
import { appInstanceId } from "../../App";
import { RequestContext } from "../../providers/RequestProvider";

const realtimeURL = `${process.env.REACT_APP_REALTIME_WS_URL}?userType=2&instanceID=${appInstanceId}&platform=browser`;

const RealtimeWebsocket = () => {
  const { updateItem, addMessage } = useContext(RequestContext);

  const onError = useCallback(err => {
    console.error("websocket error", err);
  }, []);

  const onMessage = useCallback(
    message => {
      if (message?.type?.match("^Request")) {
        const itemId = message?.resource;
        const item = message?.body?.metadata?.data;
        item.id = itemId;
        updateItem({ item, itemId });
      }

      if (message?.type?.match("^Match")) {
        const itemId = message?.resource;
        const item = message?.body?.metadata?.data;
        item.id = itemId;
        item.requestId = message?.body?.metadata?.requestId;
        updateItem({ item, itemId });
      }

      if (message?.type === "MessageSent" && message?.source === "match") {
        const itemId = message.resource;
        const {
          message: chatMessage,
          id,
          senderId,
          senderType,
          metadata,
        } = message.body;
        const newMessage = {
          id,
          channelId: itemId,
          type: "match",
          createdAt: Math.floor(message.createdAt / (1000 * 1000 * 1000)),
          senderId,
          senderType,
          message: chatMessage,
          metadata,
        };
        addMessage(itemId, newMessage);
      }
    },
    [updateItem, addMessage],
  );

  return (
    <WebSocket url={realtimeURL} onError={onError} onMessage={onMessage} />
  );
};

export default RealtimeWebsocket;
