import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { ErrorExclamation } from "@sussex/react-kit/assets";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmBox = styled.div`
  width: 275px;
  height: 180px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: ${({ theme }) => theme.fontSize.smallXl};
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }
`;

const Body = styled.div`
  padding: 10px 0;
`;

const Button = styled.a`
  padding: 10px 0;
  display: block;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  text-decoration: none;
  text-align: center;
`;

const InactiveOverlay = () => {
  const [expiredSessionTitle, expiredSessionBody, backToLoginText] = useCopy([
    "client.sessionExpired.title",
    "client.sessionExpired.body",
    "client.sessionExpired.backToLogin",
  ]);
  return (
    <Overlay>
      <ConfirmBox>
        <Title>
          <ErrorExclamation />
          <span>{expiredSessionTitle}</span>
        </Title>
        <Body>{expiredSessionBody}</Body>
        <Button href="/">{backToLoginText}</Button>
      </ConfirmBox>
    </Overlay>
  );
};

export default InactiveOverlay;
