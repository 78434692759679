import { useContext } from "react";
import styled, { css } from "styled-components";

import {
  Relationships,
  Preferences,
  AttributeCategories,
  Statuses,
} from "@sussexdir/match-utils";
import { AttributeContext } from "../../providers/AttributeProvider";

import useCopy from "../../hooks/useCopy";
import { swapCopyVariables } from "@sussex/react-kit/utils";

import CoupleFaces from "../../assets/CoupleFaces";
import ChildFace from "../../assets/ChildFace";
import MaleFace from "../../assets/MaleFace";
import FemaleFace from "../../assets/FemaleFace";
import PersonFace from "../../assets/PersonFace";

const Wrapper = styled.div`
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  height: 60%;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    height: 50%;
  }
  overflow-y: auto;
  padding: 16px;
  background: white;
  box-shadow: 0px 5px 6px 0px rgba(31, 41, 55, 0.05),
    0px 10px 15px 0px rgba(31, 41, 55, 0.1);
  z-index: 9;
`;

const RequestWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.light};
    `}
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  margin-right: 16px;
`;

const RequestDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Created = styled.div`
  color: ${({ theme }) => theme.colors.medGray};
`;

const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  margin: 16px 0;
`;

const GetIcon = getAttribute => r => {
  const gender = r.preferences[Preferences.clientGender];
  const genderAttributeId = gender?.[0]?.value;
  const genderAttribute = getAttribute({
    category: AttributeCategories.gender,
    id: genderAttributeId,
  });
  const genderKey = genderAttribute?.key;
  switch (r.relationship) {
    case Relationships.child:
      return ChildFace;
    case Relationships.couple:
      return CoupleFaces;
    case Relationships.self:
      switch (genderKey) {
        case "male":
        case "men":
          return MaleFace;
        case "female":
        case "women":
          return FemaleFace;
        default:
          return PersonFace;
      }
    default:
      return PersonFace;
  }
};

const Request = ({ request, active, setActive }) => {
  const { getAttribute } = useContext(AttributeContext);
  const ActiveIcon = GetIcon(getAttribute)(request);
  const [canceledText, forText, createdText] = useCopy([
    "client.request.canceledTitle",
    "client.forName",
    "client.createdText",
  ]);
  const isCanceled = request.status === Statuses.canceled;
  return (
    <RequestWrapper
      key={request.id}
      active={active}
      onClick={() => setActive(request.id)}
    >
      <Icon>
        <ActiveIcon />
      </Icon>
      <RequestDetails>
        <Name>{swapCopyVariables(forText, { NAME: request.name })}</Name>
        <Created>
          {isCanceled
            ? canceledText
            : swapCopyVariables(createdText, {
                DATE: new Date(request.createdAt * 1000).toLocaleDateString(
                  navigator.locale,
                  {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  },
                ),
              })}
          {}
        </Created>
      </RequestDetails>
    </RequestWrapper>
  );
};

const RequestsDropdown = ({
  requests,
  closedRequests,
  activeRequest,
  setActive,
}) => {
  return (
    <Wrapper>
      {requests.map(r => (
        <Request
          request={r}
          active={r.id === activeRequest}
          setActive={setActive}
          key={r.id}
        />
      ))}
      {closedRequests?.length ? <Line /> : null}
      {closedRequests?.map(r => (
        <Request
          request={r}
          active={r.id === activeRequest}
          setActive={setActive}
          key={r.id}
        />
      ))}
    </Wrapper>
  );
};

export default RequestsDropdown;
