import styled, { css, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@sussex/react-kit/hooks";
import { Statuses } from "@sussexdir/match-utils";
import useCopy from "../../hooks/useCopy";
import Photo from "../Photo";
import { MessagePreview } from "@sussex/react-kit/message-center";
import BaristaGirl from "../../assets/therapist-illustration.png";

const List = styled.div``;
const MatchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 5px 0 5px 16px;
  border-radius: 16px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.light};
    `}
`;

const PhotoWrapper = styled.div`
  flex: 0 0 auto;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Line = styled.div`
  position: relative;
  overflow: visible;
  height: 1px;
  width: 75%;
  background-color: ${({ theme }) => theme.colors.medGray};
  margin: 24px auto;
`;

const LineText = styled.div`
  position: absolute;
  top: -8px;
  background: white;
  right: 50%;
  transform: translateX(50%);
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.medGray};
`;

const Illustration = styled.div`
  margin-right: 12px;
  overflow: hidden;

  > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
`;

const Match = ({ match, active, onClick }) => {
  return (
    <MatchWrapper onClick={onClick} active={active}>
      <PhotoWrapper>
        <Photo item={match} />
      </PhotoWrapper>
      <Details>
        <Name>{match.therapist.fullName}</Name>
        <MessagePreview message={match.latestMessage} id={match.id} lines={1} />
      </Details>
    </MatchWrapper>
  );
};

const MatchPlaceholder = ({ request, active, onClick }) => {
  const [
    pendingTitle,
    pendingMessage,
    canceledTitle,
    canceledMessage,
    timedoutTitle,
    timedoutMessage,
  ] = useCopy([
    "client.request.title",
    "client.request.message",
    "client.request.canceledTitle",
    "client.request.canceledMessage",
    "client.request.timedoutTitle",
    "client.request.timedoutMessage",
  ]);

  const title = () => {
    switch (request.status) {
      case Statuses.canceled:
        return canceledTitle;
      case Statuses.timedout:
        return timedoutTitle;
      default:
        return pendingTitle;
    }
  };

  const message = () => {
    switch (request.status) {
      case Statuses.timedout:
        return timedoutMessage;
      case Statuses.canceled:
        return canceledMessage;
      default:
        return pendingMessage;
    }
  };

  return (
    <MatchWrapper active={active} onClick={onClick}>
      <PhotoWrapper>
        <Illustration>
          <img src={BaristaGirl} alt={pendingTitle} />
        </Illustration>
      </PhotoWrapper>
      <Details>
        <Name>{title()}</Name>
        <MessagePreview message={message()} id={request.id} lines={1} />
      </Details>
    </MatchWrapper>
  );
};

const MatchesList = ({ request, matches, closedMatches, activeMatch }) => {
  const navigate = useNavigate();
  const { breakpointsInt } = useTheme();
  const { width } = useWindowSize();
  const isDesktop = width >= breakpointsInt[">mobile"];
  const hasPlaceholder =
    [Statuses.canceled].includes(request.status) ||
    matches.length === 0 ||
    request.matchesCreationMode === "always";

  const [archivedText] = useCopy(["client.matchesList.archived"]);

  const handleClick = m => () => {
    if (activeMatch === m.id) {
      return;
    }
    navigate(`/requests/${m.requestId}/${m.id}`);
  };

  return (
    <List>
      {hasPlaceholder && (
        <MatchPlaceholder
          request={request}
          active={isDesktop && (!activeMatch || activeMatch === "placeholder")}
          onClick={() => {
            navigate(`/requests/${request.id}/placeholder`);
          }}
        />
      )}
      {matches.map(m => (
        <Match
          key={m.id}
          match={m}
          onClick={handleClick(m)}
          active={activeMatch === m.id}
        />
      ))}
      {closedMatches?.length ? (
        <Line>
          <LineText>{archivedText}</LineText>
        </Line>
      ) : null}
      {closedMatches?.map(m => (
        <Match
          key={m.id}
          match={m}
          onClick={handleClick(m)}
          active={activeMatch === m.id}
        />
      ))}
    </List>
  );
};

export default MatchesList;
